import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { baseUrl } from "../../common/common";
import '../../common/components/css/style.css';
import '../../common/components/css/style1.css';
import axios from 'axios';
import { Calendar, Views } from "react-big-calendar";
import moment from "moment-timezone";
import momentLocalizer from 'react-big-calendar/lib/localizers/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ImportComponent from "../Import/Import";
import CustomToolbar from "./ToolbarComponent";
import { WindmillSpinner } from 'react-spinner-overlay';
import validator from "validator";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";
import { Tooltip } from 'react-tooltip';

export default function StudentCalendar() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState([]);
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const localizer = momentLocalizer(moment);

    const [program, setProgram] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [category, setCategory] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const selectedCategory = categoryData.find(data => data.id === category);
    const fetchCategoryData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_event_category",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setCategoryData(response.data.data);
                console.log(response);
            }
        });
    }

    const [foodTimeData, setFoodTimeData] = useState([]);
    const selectedStartTime = foodTimeData.find(data => data.id === startTime);
    const selectedEndTime = foodTimeData.find(data => data.id === endTime);
    const fetchFoodTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTimeData(response.data.data);
                console.log(response);
            }
        });
    }


    const [events, setEvents] = useState([]);
    const [showEventPopup, setShowEventPopup] = useState(false);
    const [showAddEventPopup, setShowAddEventPopup] = useState(false);
    const [newEvent, setNewEvent] = useState({
        id: '',
        title: '',
        start: '',
        end: '',
        startTime: '',
        endTime: '',
        program: '',
        category: '',
        notify: true,
        description: ''
    });
    const [createEvent, setCreateEvent] = useState({
        id: "",
        title: "",
        start: "",
        end: "",
        startTime: "",
        endTime: "",
    });

    const [addUpdateStatus, setAddUpdateStatus] = useState('');
    /* 31-07-2024 */
    const [currentView, setCurrentView] = useState(() => {
        return localStorage.getItem('currentView') || Views.WEEK;
    });

    const handleViewChange = (view) => {
        setCurrentView(view);
        localStorage.setItem('currentView', view);
    };

    useEffect(() => {
        const storedView = localStorage.getItem('currentView');
        if (storedView && storedView !== currentView) {
            setCurrentView(storedView);
        }
    }, [currentView]);

    useEffect(() => {
        localStorage.setItem('currentView', currentView);
        console.log("Current view is now:", currentView);
    }, [currentView]);

    useEffect(() => {
        return () => {
            localStorage.removeItem('currentView');
        };
    }, []);

    const formatCalendarDate1 = (date) => {
        return date
            .toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-");
    };

    const formatCalendarDate = (date) => {
        // Check if date is already a Date object
        const validDate = typeof date === "string" ? new Date(date) : date;

        // Check if validDate is indeed a valid Date instance
        if (validDate instanceof Date && !isNaN(validDate)) {
            return validDate
                .toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })
                .split("/")
                .reverse()
                .join("-");
        }

        return null;
    };

    /* 29-8-2024 */
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedRange, setSelectedRange] = useState(null);

    const handleSelectSlot = async ({ start, end }) => {
        setEndTimeError(null);
        setStartTimeError(null);
        setShowEventPopup(false);
        setAddUpdateStatus("add");
        setCategory("");
        setEndTime("");
        setStartTime("");
        setProgram(null);
        /* 29-8-2024 */
        if (selectedDate) {
            setEvents((prevEvents) =>
                prevEvents.filter(event => event.id !== '')
            );
        }
        const adjustedEnd = new Date(end);
        adjustedEnd.setDate(adjustedEnd.getDate() - 1);
        const newEvent = {
            id: "",
            title: "",
            start: formatCalendarDate(start),
            end: currentView === "month" ? formatCalendarDate(adjustedEnd) : formatCalendarDate(end),
            startTime: "",
            endTime: "",
            program: "",
            category: "",
            notify: true,
            description: "",
        };
        const createEvent = {
            id: "",
            title: "",
            start: start,
            end: currentView === "month" ? formatCalendarDate(adjustedEnd) : end,
            startTime: "",
            endTime: "",
        };
        setSelectedDate(newEvent.start);
        setCreateEvent(createEvent);
        setNewEvent(newEvent);
        setShowAddEventPopup(true);
        setEvents((prevEvents) => [...prevEvents, createEvent]);
    };

    const [titleError, setTitleError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const [endTimeError, setEndTimeError] = useState(null);
    const [programError, setProgramError] = useState(null);
    /* 29-8-2024 */
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const handleAddEvent = () => {
        let hasError = false;

        if (newEvent.title === "") {
            setTitleError("Please Enter Event Name");
            hasError = true;
        } else {
            setTitleError(null);
        }
        // if (!category) {
        //     setCategoryError("Please Select a Category");
        //     hasError = true;
        // } else {
        //     setCategoryError(null);
        // }
        if (startTime === "") {
            setStartTimeError("Please Select Start Time");
            hasError = true;
        } else {
            setStartTimeError(null);
        }
        if (endTime === "") {
            setEndTimeError("Please Select End Time");
            hasError = true;
        } else {
            setEndTimeError(null);
        }

        if (hasError) return;

        const formattedStartDate = `${newEvent.start} ${selectedStartTime ? selectedStartTime.time_name : '00:00'}`;
        const formattedEndDate = `${newEvent.end} ${selectedEndTime ? selectedEndTime.time_name : '00:00'}`;
        //26.07.2024
        const postData = {
            school_id: localStorage.getItem('schoolId'),
            event_type: 1,
            // programs_id: newEvent.program,
            programs_id: localStorage.getItem('programId'),
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            description: newEvent.description,
            title: newEvent.title,
            from_time_id: startTime,
            to_time_id: endTime,
            calendar_status: 0,
            status: 1,
            notify: newEvent.notify == true ? 1 : 0 /* 11-09-2024 */
        };

        if (newEvent.id && newEvent.id !== "") {
            postData.id = newEvent.id;
            postData.updated_by = localStorage.getItem('loginUserId');
        } else {
            postData.created_by = localStorage.getItem('loginUserId');
        }
        setTitleError(null);
        setStartTimeError(null);
        setEndTimeError(null);
        console.log(postData);
        axios({
            method: "post",
            url: `${baseUrl}/api/timetable_add_edit`,
            data: postData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setShowAddEventPopup(false);
                /* 11-09-2024 start*/
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                fetchCalendarData();
                if (newEvent.id === "") {
                    setRefreshNotification(true);
                }
                const newEventStartDate = moment(response.data.data.start_date, 'YYYY-MM-DD hh:mm A');
                setCurrentMonth(newEventStartDate);
                /* 11-09-2024 end*/
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        }).catch((error) => {
            console.error("There was an error adding/updating the event!", error);
        });
    };

    /* 29-8-2024 */
    const handleSelecting = ({ start, end }) => {
        setSelectedRange({ start, end });
        return true; // Allow selection
    };


    const [deleteEventId, setDeleteEventId] = useState(null);
    const handleDeleteEvent = () => {
        axios({
            method: "post",
            url: `${baseUrl}/api/timetable_delete`,
            data: {
                id: deleteEventId,
                updated_by: localStorage.getItem('loginUserId')
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                handleRecycleBinClose();
                fetchCalendarData();
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        }).catch((error) => {
            console.error("There was an error adding/updating the event!", error);
        });
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setNewEvent((prevEvent) => ({
            ...prevEvent,
            [name]: newValue,
        }));
        if (name === "start" || name === "end" || name === "title") {
            setCreateEvent((prevEvent) => ({
                ...prevEvent,
                [name]: newValue,
            }));
        }
    };

    const filterEventsWithId = (events) => {
        return events.filter(event => event.id !== '');
    };

    const handleCreatEventTime = (time, item) => {
        setCreateEvent((prevEvent) => ({
            ...prevEvent,
            [time]: item.time_name,
        }));
    }

    const handleFormEmpty = () => {
        setTitleError(null);
        setProgram(null);
        setProgramError(null);
        setCategoryError(null);
        const newEvent = {
            id: "",
            title: "",
            start: "",
            end: "",
            startTime: "",
            endTime: "",
            program: "",
            category: "",
            notify: true,
            description: "",
        };
        const createEvent = {
            id: "",
            title: "",
            start: "",
            end: "",
            startTime: "",
            endTime: "",
        };
        setCreateEvent(createEvent);
        setNewEvent(newEvent);
        setEvents(filterEventsWithId(events));
    }

    const handleSelectEvent = (event) => {
        setEndTimeError(null);
        setStartTimeError(null);
        setShowAddEventPopup(false);
        setAddUpdateStatus("update");
        setNewEvent({});
        console.log(event);
        // const {start, end } = event;
        setCategory(event.event_type);
        setEndTime(event.to_time_id);
        setStartTime(event.from_time_id);
        setProgram(event.program_id);
        const newEvent = {
            id: event.id,
            title: event.title,
            start: formatCalendarDate(event.start),
            end: formatCalendarDate(event.end),
            startTime: event.from_time_id,
            endTime: event.to_time_id,
            program: event.program_id,
            category: event.event_type,
            notify: true,
            description: event.description,
            title: event.title,
            calendar_status: event.calendar_status,
        };
        const createEvent = {
            title: event.title,
            start: formatCalendarDate(event.start),
            end: formatCalendarDate(event.end),
            startTime: event.from_time_id,
            endTime: event.to_time_id,
        }
        setCreateEvent(createEvent);
        setNewEvent(newEvent);
        if (event.title) setShowEventPopup(true);
        // setShowAddEventPopup(true);
    };

    const [calendarData, setCalendarData] = useState([]);
    const [calendarLoading, setCalendarLoading] = useState(false);

    const fetchCalendarData = async () => {
        setCalendarLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                programs_id: localStorage.getItem('programId')
            };
            const { data } = await axios.post(baseUrl + "/api/get_timetable", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setCalendarData(data.data)
            // const fetchedEvents = data.data.map(event => ({
            //     id: event.id,
            //     title: event.title,
            //     event_type: event.event_type,
            //     program_id: event.programs_id,
            //     from_time_id: event.from_time_id,
            //     to_time_id: event.to_time_id,
            //     description: event.description,
            //     title: event.title,
            //     start: new Date(...event.start_date),
            //     end: new Date(...event.end_date)
            // })
            // );
            const fetchedEvents = data.data.map((event) => {
                const [startYear, startMonth, startDay, startHour, startMinute] = event.start_date;
                const [endYear, endMonth, endDay, endHour, endMinute] = event.end_date;

                let startDate = new Date(startYear, startMonth, startDay, startHour, startMinute);
                let endDate = new Date(endYear, endMonth, endDay, endHour, endMinute);

                if (startHour === 0 && startMinute === 0) {
                    startDate.setHours(8, 0, 0);
                }
                if (endHour === 0 && endMinute === 0) {
                    endDate.setHours(20, 0, 0);
                }

                return {
                    id: event.id,
                    title: event.title,
                    event_type: event.event_type,
                    program_id: event.programs_id,
                    from_time_id: event.from_time_id,
                    to_time_id: event.to_time_id,
                    description: event.description,
                    title: event.title,
                    calendar_status: event.calendar_status,
                    start: startDate,
                    end: endDate,
                };
            });
            setEvents(fetchedEvents);
        } catch (error) {
            console.log(error);
        } finally {
            setCalendarLoading(true);
        }
    };


    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    const Event = ({ event }) => {
        const startTime = moment(event.start).format('h:mm A');
        const endTime = moment(event.end).format('h:mm A');
        return (
            <span>
                <strong>{event.title}</strong><br /><br />
                {currentView === 'month' ? `${startTime} - ${endTime}` : null}
            </span>
        );
    };

    // -------------------------19-06-------------------------
    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
    const handleOpenNewProgramPopupVisible = (status) => {
        setNewProgramAddUpdateStatus(status);
    }

    // -------------------31-08--------------

    const [deleteProgramConfirmVisible, setDeleteProgramConfirmVisible] = useState(false);

    const handleDeleteProgramConfirmClose = () => {
        setDeleteProgramConfirmVisible(false);
    };

    const handleDeleteProgramConfirm = () => {
        // setCheckProgramChangeStatus(true);
        setDeleteProgramConfirmVisible(false);
        handleRecycleBin();
    };

    const [teachersData, setTeachersData] = useState([]);


    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {
            setimportstatus(false);
        }
    };

    /* 28-8-2024 */
    /* 08-10-24 start */
    // Check if the provided date is the selected date
    const isDateSelected = (date) => {
        return dayjs(date).isSame(dayjs(selectedDate), 'day');
    };

    // dayPropGetter for month view
    const customDayPropGetter = (date) => {
        const className = isDateSelected(date) ? 'selected-date' : '';
        return { className };
    };

    const eventPropGetter = (event, start, end, isSelected) => {
        const eventDate = dayjs(event.start).startOf('day');
        const selectedDateValue = dayjs(selectedDate).startOf('day');

        // Add class to the event if it matches the selected date
        return {
            className: eventDate.isSame(selectedDateValue) ? 'selected-date' : '',
        };
    };
    /* 08-10-24 end */

    const CustomTimeGutterHeader = () => {
        return (
            <div className="custom-time-gutter-header">
                <i class="fa-regular fa-clock"></i>
            </div>
        );
    };

    /* 31-8-2024 */
    const CustomEvent = ({ event }) => {
        const startTime = moment(event.start).format("h:mm A");
        const endTime = moment(event.end).format("h:mm A");
        const formattedTitle = event.title.charAt(0).toUpperCase() + event.title.slice(1);
        let isNewEvent = event.id === '';
        const containerClass = isNewEvent ? 'custom-event-content' : 'rbc-event-content';
        const eventClass = isNewEvent ? 'new-event' : 'custom-event';
        let start = startTime === "12:00 AM" ? "08:00 AM" : startTime;
        let end = endTime === "12:00 AM" ? "08:00 PM" : endTime;

        return (
            <div className={containerClass}>
                <div className={eventClass}>
                    <div className="event-title">{formattedTitle}</div>
                    <br />
                    <div className="event-dates">{`${start} to ${end}`}</div>
                </div>
            </div>
        );
    };


    // -------------------------06-07------------------------------------
    // ----------add new student----------------

    const formData = new FormData();

    const gender1 = [
        {
            id: 1,
            value: "Male",
        },
        {
            id: 2,
            value: "Female",
        },
    ];

    const Blood1 = [
        {
            id: 1,
            value: "A+",
        },
        {
            id: 2,
            value: "A-",
        },
        {
            id: 3,
            value: "O+",
        },
        {
            id: 4,
            value: "B-",
        },
        {
            id: 5,
            value: "B+",
        },
        {
            id: 6,
            value: "B+",
        },
    ];

    const [user, setUser] = useState({
        school_id: localStorage.getItem("schoolId"),
        studentID: "",
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        profile_image: null,
    });

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setUser({ ...user, profile_image: file });
        setSelectedImage(file);
    };

    const convertDBDateFormat = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };
    const dateFormatList = 'DD-MM-YYYY';

    const ondobChange = (date, dateString) => {
        setUser({ ...user, dob: dateString });
        console.log(date, dateString);
    };

    const ondojChange = (date, dateString) => {
        setUser({ ...user, joining_date: dateString });
        console.log(date, dateString);
    }
    const onSubmit = (ev) => {
        console.log("formData", formData)
        if (validateForm()) {
            console.log(user);
            axios({
                method: "post",
                url: baseUrl + "/api/student_add",
                data: {
                    school_id: localStorage.getItem("schoolId"),
                    first_name: user.first_name,
                    last_name: "",
                    gender: user.gender,
                    dob: convertDBDateFormat(user.dob),
                    programs_id: user.programs_id,
                    joining_date: (user.joining_date !== "" && user.joining_date !== null) ? convertDBDateFormat(user.joining_date) : "",
                    blood_group: user.blood_group,
                    address: user.address,
                    father_name: user.father_name,
                    mother_name: user.mother_name,
                    primary_email: user.primary_email,
                    secondary_email: user.secondary_email,
                    primary_phone: user.primary_phone,
                    secondary_phone: user.secondary_phone,
                    profile_image: user.profile_image,
                    student_qr_code: "",
                    status: 1,
                    created_by: localStorage.getItem('loginUserId'),
                    studentID: user.studentID,
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response["data"]["status"] === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    $(".overlay").removeClass("visible");
                    $(".popup-modal-container").removeClass("visible");
                    setUser({
                        school_id: localStorage.getItem("schoolId"),
                        first_name: "",
                        last_name: "",
                        gender: "",
                        programs_id: "",
                        dob: "",
                        joining_date: "",
                        blood_group: "",
                        address: "",
                        father_name: "",
                        mother_name: "",
                        primary_email: "",
                        secondary_email: "",
                        primary_phone: "",
                        secondary_phone: "",
                        student_qr_code: "",
                        status: 1,
                        created_by: "",
                        updated_by: "",
                        studentID: "",
                        profile_image: null,
                    });
                    console.log(response);
                    fetchCalendarData();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    };

    const [validationErrors, setValidationErrors] = useState({
        first_name: "",
        gender: "",
        dob: "",
        programs_id: "",
        profile_image: "",
        joining_date: "",
        studentID: "",
        blood_group: "",
        address: "",
        father_name: "",
        primary_phone: "",
        primary_email: "",
        mother_name: "",
        secondary_phone: "",
        secondary_email: "",
    });

    const validateForm = () => {
        let errors = {};
        if (user.first_name === "") {
            errors.first_name = "Student name cannot be empty";
        }
        if (user.gender === "") {
            errors.gender = "Student gender cannot be empty";
        }
        if (user.dob === "") {
            errors.dob = "Student date of birth cannot be empty";
        }
        if (user.programs_id === "") {
            errors.programs_id = "Student programs cannot be empty";
        }
        if (user.address === "") {
            errors.address = "Student address cannot be empty";
        }
        if (user.father_name === "") {
            errors.father_name = "Student father name cannot be empty";
        }
        if (user.primary_phone.trim() === "") {
            errors.primary_phone = "Student father mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.primary_phone.trim()) ||
            user.primary_phone.trim().length !== 10
        ) {
            errors.primary_phone = "Please enter valid mobile number";
        }
        if (user.mother_name === "") {
            errors.mother_name = "Student mother name cannot be empty";
        }
        if (user.secondary_phone.trim() === "") {
            errors.secondary_phone = "Student mother mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.secondary_phone.trim()) ||
            user.secondary_phone.trim().length !== 10
        ) {
            errors.secondary_phone = "Please enter valid mobile number";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [programs, setPrograms] = useState([]);

    const fetchPrograms = async () => {
        try {
            const response = await axios.post(baseUrl + "/api/get_programs", {
                school_id: localStorage.getItem("schoolId")
            }, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setPrograms(response.data.data);
        } catch (error) { }
    };

    const selectedProgram = programs.find(
        (program) => program.id === user.programs_id
    );

    const selectedGender = gender1.find(
        (gender) => gender.value === user.gender
    );

    const selectedBlood = Blood1.find(
        (blood_group) => blood_group.value === user.blood_group
    );

    const firstNameInputRef = useRef(null);
    const genderInputRef = useRef(null);
    const dobInputRef = useRef(null);
    const programInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const joingInputRef = useRef(null);
    const studentInputRef = useRef(null);
    const bloodInputRef = useRef(null);
    const adressInputRef = useRef(null);
    const fathernameInputRef = useRef(null);
    const fatherphoneInputRef = useRef(null);
    const fatheremailInputRef = useRef(null);
    const mathernameInputRef = useRef(null);
    const matherphoneInputRef = useRef(null);
    const matheremailInputRef = useRef(null);
    const submitButtonRef = useRef(null);

    const startTimeInputRef = useRef(null);
    const endTimeInputRef = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            console.log("Next input ref:", nextInputRef); // Log the next input ref
            if (nextInputRef && nextInputRef.current) {
                nextInputRef.current.focus();
            } else {
                console.log("Next input ref is null");
            }
        }
    };

    const initialBorderColor = {
        genderBorder: "",
        programsBorder: "",
        bloodGroupBorder: "",
        startTimeBorder: "",
        endTimeBorder: ""
    };

    const [borderColor, setBorderColor] = useState(initialBorderColor);

    const handleFocus = (borderColor) => {
        if (borderColor === "gender") {
            setBorderColor({ ...borderColor, genderBorder: "#4D6EE3" });
        } else if (borderColor === "programs") {
            setBorderColor({ ...borderColor, programsBorder: "#4D6EE3" });
        } else if (borderColor === "bloodGroup") {
            setBorderColor({ ...borderColor, bloodGroupBorder: "#4D6EE3" });
        }
        else if (borderColor === "startTime") {
            setBorderColor({ ...borderColor, startTimeBorder: "#4D6EE3" });
        }
        else if (borderColor === "endTime") {
            setBorderColor({ ...borderColor, endTimeBorder: "#4D6EE3" });
        }
    };

    const handleBlur = () => {
        setBorderColor(initialBorderColor);
    };

    const handleClickOutside1 = (event) => {
        if (genderInputRef.current && !genderInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
        if (programInputRef.current && !programInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
        if (bloodInputRef.current && !bloodInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
        if (startTimeInputRef.current && !startTimeInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
        if (endTimeInputRef.current && !endTimeInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside1);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, []);

    useEffect(() => {
        $(document).on("click", "#newStudentCreate", function () {
            setBorderColor(initialBorderColor);
            setSelectedImage(null);
            setUser({
                school_id: localStorage.getItem("schoolId"),
                first_name: "",
                last_name: "",
                gender: "",
                programs_id: "",
                dob: "",
                joining_date: "",
                blood_group: "",
                address: "",
                father_name: "",
                mother_name: "",
                primary_email: "",
                secondary_email: "",
                primary_phone: "",
                secondary_phone: "",
                student_qr_code: "",
                status: 1,
                created_by: "",
                updated_by: "",
                studentID: "",
                profile_image: null,
            });
            setValidationErrors({
                first_name: "",
                gender: "",
                dob: "",
                programs_id: "",
                profile_image: "",
                joining_date: "",
                studentID: "",
                blood_group: "",
                address: "",
                father_name: "",
                primary_phone: "",
                primary_email: "",
                mother_name: "",
                secondary_phone: "",
                secondary_email: "",
            });
            $(".overlay").addClass("visible");
            $(".popup-modal-container").addClass("visible");
        });
        $(document).on("click", ".sp-btn-cancel", function () {
            setBorderColor(initialBorderColor);
            $(".overlay").removeClass("visible");
            $(".popup-modal-container").removeClass("visible");
        });
    }, []);

    // -------11-07-----------------

    const editProgramStyle = {
        position: "absolute",
        left: "430px",
        top: "55px",
        margin: "0px",
        maxWidth: "140px"
    }

    const editProgramPopupRef = useRef(null);
    const [editProgramVisible, setEditProgramVisible] = useState(false);
    const handleClickOutside = (event) => {
        if (editProgramPopupRef.current && !editProgramPopupRef.current.contains(event.target)) {
            setEditProgramVisible(false);
        }
    };

    useEffect(() => {
        if (editProgramVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editProgramVisible]);

    const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);
    const [isRecycleBinStatus, setRecycleBinStatus] = useState(null);
    const handleRecycleBinClick = (status, deleteId) => {
        setRecycleBinStatus(status);
        setDeleteEventId(deleteId);
        setRecycleBinVisible(true);
    };

    const handleRecycleBinClose = () => {
        setRecycleBinStatus(null);
        setDeleteEventId(null);
        setRecycleBinVisible(false);
    };

    const handleRecycleBin = () => {
        if (isRecycleBinStatus === "program") {
            handleProgramRecycleBin();
        } else {
            handleDeleteEvent();
        }
    }

    const handleProgramRecycleBin = async () => {
        const url = `${baseUrl}/api/programs_delete`;
        const data = {
            id: localStorage.getItem("programId"),
            updated_by: localStorage.getItem('loginUserId')
        };
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
        };
        try {
            const response = await axios.post(url, data, { headers });
            console.log(response);
            if (response.data.status === true) {
                setRecycleBinVisible(false);
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                fetchProgramsData();
                localStorage.removeItem("programName");
                localStorage.removeItem("programId");
                navigate('/student_list');
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        } catch (error) {
            console.error(error);
        }
    };

    {/* 26-8-24 */ }
    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${day}-${month}-${year}`;
    };

    //26.07.2024
    useEffect(() => {
        if (newEvent.start && newEvent.end) {
            const startDate = new Date(newEvent.start);
            const endDate = new Date(newEvent.end);

            if (startDate > endDate) {
                setSnackbarMessage("End Date cannot be less than Start Date.");
                setMessageType("error");
                handleSnackbarOpen();
                return;
            }
            //  else {
            //   setErrorMessage("");
            // }
        }
    }, [newEvent.start, newEvent.end]);
    //26.07.2024
    useEffect(() => {
        console.log("newEvent state updated:", newEvent.start, newEvent.end);
    }, [newEvent]);


    const [refreshNotification, setRefreshNotification] = useState(false);

    useEffect(() => {
        fetchPrograms();
        fetchCategoryData();
        fetchFoodTimeData();
        fetchProgramsData();
        fetchTeachersData();
        fetchCalendarData();
    }, [])


    return (
        <section className="staff_atten_cus">
            <Snackbar className={`snack_total ${messageType === 'success' ? "snackbar_success" : "snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <div>
                                {messageType === 'success' ?
                                    <span class="icon-List_Present" style={{ fontSize: " 21px;" }}>
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                    </span> :
                                    <span class="icon-List_Absent" style={{ fontSize: " 21px;" }}>
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                        <span class="path3"></span>
                                        <span class="path4"></span>
                                        <span class="path5"></span>
                                        <span class="path6"></span>
                                    </span>
                                }
                            </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                    <CommonSidebarHeader />
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="name_tag_side">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard" />
                                            <p>Dashboard</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity" />
                                            <p>Activity</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable" />
                                            <p>Calendar</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff" />
                                            <p>Staff</p>
                                        </span>
                                    </li>

                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible("add")}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchCalendarData();
                                                    navigate('/student_timetable');
                                                }}>
                                                    <span
                                                        className="ft-400 truncate"
                                                        data-tooltip-id={`program-tooltip-${data.id}`}
                                                        data-tooltip-content={data.program_name}
                                                        data-tooltip-place="bottom"
                                                    >
                                                        <i className="icon-Program_1" />
                                                        <p>
                                                            {
                                                                (data.program_name && data.program_name.length <= 25) ? data.program_name : `${data.program_name.slice(0, 25)}...`
                                                            }
                                                        </p>
                                                    </span>
                                                    {data.program_name.length > 25 && <Tooltip id={`program-tooltip-${data.id}`} />}
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                          type="spinner-cub"
                                          bgColor="#4D6EE3"
                                          color="#6B6F8C"
                                          title="Loading..."
                                          size={35}
                                        /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }

                                </ul>
                            </div>
                        </div>
                        <CommonSidebarFooter refreshNotification={refreshNotification} setRefreshNotification={setRefreshNotification} />
                        {/* <CommonSidebarFooter /> */}
                    </div>
                </div>
                {/* my code */}
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri stude_li_edi">
                            <span className="selected-icon">
                                <i className="icon-Program_1" />
                            </span>
                            <p
                                className="program_tit"
                                data-tooltip-id={`program-tooltip`}
                                data-tooltip-content={localStorage.getItem("programName")}
                                data-tooltip-place="bottom"
                                style={{
                                    cursor: localStorage.getItem("programName").length > 25 && "pointer"
                                }}
                            >
                                {
                                    (localStorage.getItem("programName").length <= 25) ? localStorage.getItem("programName") : `${localStorage.getItem("programName").slice(0, 25)}`
                                }
                                {/* {localStorage.getItem("programName")} */}
                            </p>
                            {localStorage.getItem("programName").length > 25 && <Tooltip id={`program-tooltip`} />}
                            {/* <i className={`icon-More-Icon program-icon-style ${editProgramVisible && "active-Icon"}`} onClick={() => setEditProgramVisible(true)} /> */}
                            <span className="pos_fix_edit_student" ref={editProgramPopupRef}>
                                {/* <i className="icon-More-Icon" /> */}
                                <div className="svg_hide_drop">
                                    <Dropdown
                                        label={
                                            <span
                                                onClick={() => setEditProgramVisible(true)}
                                                style={{
                                                    color: "#1B252F",
                                                    padding: "0px",
                                                    height: "10px"
                                                }}
                                            >
                                                {
                                                    editProgramVisible === false
                                                        ? <i className="icon-More active_ic_more" />
                                                        : <i className="icon-More active_ic_more active_click_more" />
                                                }
                                            </span>
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <Dropdown.Item onClick={() => handleOpenNewProgramPopupVisible("edit")} className="pad_progr1">
                                            <span class="icon-Student_Edit" style={{ width: '18px' }}></span> &nbsp; Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleRecycleBinClick("program", "")} className="pad_progr1">
                                            <span class="icon-Recycle_Modal"></span> &nbsp; Delete
                                        </Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </span>
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}>
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10 space_icon_pad" id="newStudentCreate">
                                <i class="fa-solid fa-plus"></i> New Student
                            </button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Student-List" />
                                Student List
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attandance" />
                                Attendance
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/food'); }}>
                                <i className="icon-Food" />
                                Food
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/sleep'); }}>
                                <i className="icon-Sleep" />
                                Sleep
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Calendar" />
                                Timetable
                            </div>
                        </nav>
                    </div>
                    {importstatus ?
                        <ImportComponent
                            importStudent={"Student"}
                            handleImportStatus={handleImportStatus}
                            setProgress={setProgress} setIsLoading={setIsLoading}
                        /> :
                        <div className="he_fix_cus">
                            {calendarLoading ? (
                                <div className="sp-timetab-inr">
                                    <Calendar
                                        popup //25.07.2024 - pop-up for show more
                                        localizer={localizer}
                                        events={events}
                                        onView={handleViewChange}
                                        startAccessor="start"
                                        endAccessor="end"
                                        selectable
                                        views={["month", "week"]}
                                        view={currentView} /* 31-07-2024 */
                                        date={currentMonth} /* 29-8-2024 */
                                        components={{
                                            month: {
                                                event: (props) => <CustomEvent {...props} currentView={currentView} />,
                                            },
                                            week: {
                                                event: (props) => <CustomEvent {...props} currentView={currentView} />,
                                                timeGutterHeader: CustomTimeGutterHeader,
                                            },
                                            toolbar: CustomToolbar,
                                        }}
                                        scrollToTime={new Date(1970, 1, 1, 8)}
                                        min={new Date(1970, 1, 1, 8)}
                                        max={new Date(1970, 1, 1, 21)} /* 31-8-2024 */
                                        /* 29-8-2024 */
                                        onNavigate={(newDate) => {
                                            setCurrentMonth(newDate);
                                        }}
                                        dayPropGetter={customDayPropGetter}
                                        onSelectSlot={handleSelectSlot}
                                        onSelectEvent={handleSelectEvent}
                                        onSelecting={handleSelecting} /* 29-8-2024 */
                                        showMultiDayTimes={true} /* 29-8-2024 */
                                        eventPropGetter={eventPropGetter} /* 08-10-24 */
                                    />
                                </div>
                            ) : <div className="data-loader extracss_load">
                                {/* <Loader
                                type="spinner-cub"
                                bgColor="#4D6EE3"
                                color="#6B6F8C"
                                title="Loading..."
                                size={35}
                                /> */}
                                <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                <p>Loading...</p>
                            </div>
                            }

                        </div>
                    }
                </div>
                {showAddEventPopup && (
                    <div className="dialog-model absolute create_cus_data food_sleap_lab" style={newEvent.start && { display: "block" }}>
                        <div className="sp-tt ft-14 flexc ft-wt600 food_head_style">{addUpdateStatus === 'add' ? "Create New" : "Update"} Event</div>
                        <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label fullsz-field error_mes_cus">
                                <label>Event<span className="mandatory">*</span></label>
                                <div className="field-value-holder margin_top10p">
                                    <input
                                        type="text"
                                        // className="sp-field-input"
                                        className={`sp-field-input ${newEvent.title == "" && titleError ? 'error' : ''}`}
                                        placeholder="Type event.."
                                        name="title"
                                        value={newEvent.title}
                                        onChange={handleChange}
                                        style={{ fontSize: '13px', height: "40px" }}
                                    />
                                </div>
                                {/* {titleError && <p className="error">{titleError}</p>} */}
                                {/* /* 31-07-2024 */}
                                {newEvent.title === "" && titleError && <p className="error">{titleError}</p>}
                            </div>
                            {/* <div className="field-label fullsz-field">
                                <label>Category</label>
                                <div className="field-value-holder margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedCategory ? selectedCategory.event_category_name : "Select Category"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {categoryData.map(item => (
                                            <Dropdown.Item
                                                className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                onClick={() => setCategory(item.id)}
                                                key={item.id}
                                            >
                                                {item.event_category_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div> */}
                            {/* <div className="field-label half-field">
                                <label>Start Date</label>
                                <div className="field-value-holder margin_top10p flexc pos_icon_date_st disable-data-field">
                                    <input
                                        type="date"
                                        className="sp-field-input date"
                                        placeholder="Start Date"
                                        name="start"
                                        value={moment(newEvent.start).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                        // readOnly
                                        disabled
                                        style={{
                                            fontSize: '13px', fontWeight: "600", height: "40px",
                                            color: '#A9AFC3'
                                            // color: "grey"

                                        }}
                                    />
                                
                                </div>
                            </div> */}
                            <div className="field-label half-field error_mes_cus">
                                <label>Start Time<span className="mandatory">*</span></label>
                                {/* <div className="field-value-holder margin_top10p flexc custom"
                                    style={{
                                        width: "100%",
                                        borderColor: startTime === "" && startTimeError ? "red" : "#D9DCE6",
                                    }}> */}
                                <div
                                    className="field-value-holder margin_top10p flexc custom"
                                    ref={startTimeInputRef}
                                    style={{
                                        width: "100%",
                                        borderColor: startTime === "" && startTimeError ? "red" : borderColor.startTimeBorder,
                                    }}
                                    onFocus={() => handleFocus("startTime")}
                                    onBlur={handleBlur}
                                >
                                    <Dropdown
                                        label={
                                            // <span
                                            //     className={`${selectedStartTime ? 'selected-item' : ''}`}
                                            //     style={{
                                            //         fontSize: "13px",
                                            //         color: selectedStartTime ? '#1B252F' : '#A9AFC3'
                                            //     }}
                                            // >
                                            //     {selectedStartTime ? selectedStartTime.time_name : "Select a Time"}
                                            // </span>
                                            <span
                                                className={`flex items-center ${selectedStartTime ? "selected-item" : ""}`}
                                                style={{
                                                    fontSize: "13px",
                                                    color: selectedStartTime ? "#1B252F" : "#292929",
                                                    width: "100%",
                                                    justifyContent: "space-between"
                                                }}
                                            >
                                                <span className="time-value">{selectedStartTime ? selectedStartTime.time_name : "Select a Time"}</span>
                                                <i className="fa-regular fa-clock"></i>
                                            </span>
                                        }
                                        // label={<span style={{ color: 'black' }}>{selectedStartTime ? selectedStartTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""}`}
                                                // className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}
                                                icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                onClick={() => {
                                                    setStartTime(item.id);
                                                    handleCreatEventTime("startTime", item); /* 27-8-2024 */
                                                }}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                                {/* {startTimeError && <p className="error">{startTimeError}</p>} */}
                                {/* /* 31-07-2024 */}
                                {startTime === "" && startTimeError && <p className="error">{startTimeError}</p>}
                            </div>
                            {/* <div className="field-label half-field">
                                <label>End Date</label>
                                <div className="field-value-holder margin_top10p flexc pos_icon_date_st disable-data-field">
                                    <input
                                        type="date"
                                        className="sp-field-input date"
                                        placeholder="End Date"
                                        name="end"
                                        value={moment(newEvent.end).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                        // readOnly
                                        disabled
                                        style={{ fontSize: '13px', fontWeight: "600", height: "40px", color: "#A9AFC3" }}
                                    />
                                </div>
                            </div> */}
                            <div className="field-label half-field error_mes_cus">
                                <label>End Time<span className="mandatory">*</span></label>
                                <div
                                    className="field-value-holder margin_top10p flexc custom"
                                    ref={endTimeInputRef}
                                    style={{
                                        width: "100%",
                                        borderColor: endTime === "" && endTimeError ? "red" : borderColor.endTimeBorder,
                                    }}
                                    onFocus={() => handleFocus("endTime")}
                                    onBlur={handleBlur}
                                >
                                    <Dropdown
                                        label={
                                            <span
                                                className={`flex items-center ${selectedEndTime ? "selected-item" : ""}`}
                                                style={{
                                                    fontSize: "13px",
                                                    color: selectedEndTime ? "#1B252F" : "#292929",
                                                    width: "100%",
                                                    justifyContent: "space-between"
                                                }}
                                            >
                                                <span className="time-value">{selectedEndTime ? selectedEndTime.time_name : "Select a Time"}</span>
                                                <i className="fa-regular fa-clock"></i>
                                            </span>
                                        }
                                        style={{ width: '100%', height: "40px", }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""}`}
                                                icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                onClick={() => {
                                                    setEndTime(item.id);
                                                    handleCreatEventTime("endTime", item); /* 27-8-2024 */
                                                }}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                                {/* /* 31-07-2024 */}
                                {endTime === "" && endTimeError && <p className="error">{endTimeError}</p>}
                            </div>

                            <div className="field-label fullsz-field">
                                <label>Programs</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="text"
                                        className="sp-field-input"
                                        placeholder="program"
                                        name="program"
                                        // value={localStorage.getItem('programName')}
                                        value={newEvent.program === 0 ? "All Programs" : localStorage.getItem('programName')}
                                        readOnly
                                        disabled
                                        style={{ fontSize: '13px', fontWeight: "600", color: "#A9AFC3", height: "40px" }}
                                    />
                                </div>
                            </div>
                            {/* <div className="notify-cont flexc">
                                <label className="label-container">
                                    <input
                                        type="checkbox"
                                        defaultChecked={newEvent.notify}
                                        name="notify"
                                        onChange={handleChange}
                                    />
                                    <span className="tick-ele" />
                                </label>
                                <span className="ft-13">
                                    Notify Staff and Parents in selected programs
                                </span>
                            </div> */}
                            <div className="field-label full-field error_mes_cus">
                                <label>Description</label>
                                <div className="field-value-holder margin_top10p">
                                    <textarea
                                        name="description"
                                        value={newEvent.description}
                                        // type="text"
                                        onChange={handleChange}
                                        className="sp-field-input fixed-height-textarea"
                                        placeholder="Description"
                                    />
                                    {/* <input
                                        className="sp-field-input"
                                        placeholder="Description"
                                        type="text"
                                        name="description"
                                        value={newEvent.description}
                                        onChange={handleChange}
                                        style={{ height: "40px" }}
                                    /> */}
                                </div>
                            </div>
                            <div className="flexc mt-4" style={{ marginTop: "14px !important" }}>
                                <div className="flexc food_sleep_saveic calender_cre_can">
                                    <button className="createN ft-wt500 mr-2" onClick={handleAddEvent}>
                                        {addUpdateStatus === 'add' ? "Create" : "Update"}
                                    </button>
                                    <button
                                        className="sp-view-btn ft-wt500 flexc cancel-btn"
                                        onClick={() => { setTitleError(null); setShowAddEventPopup(false); setSelectedDate(null); handleFormEmpty(); }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* 08-10-24 start  */}
                {showEventPopup && (
                    <div className="dialog-model absolute edit_cus_style" style={{ display: "block" }}>
                        <div className="flexc justifySp">
                            <div className="sp-tt ft-14 ft-wt600 head_cus_cal">Event Information</div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    float: "right",
                                    right: "0",
                                }}
                            >
                                {/* 11-09-2024 Start*/}
                                {new Date(newEvent.start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) && (newEvent.category === 1) && (
                                    <>
                                        <span className="selected-icon sp-accbox_edit_icon" style={{ border: "unset", cursor: "pointer" }} onClick={() => { setShowEventPopup(false); setShowAddEventPopup(true) }}>
                                            <span class="icon-Student_Edit"></span>
                                        </span>
                                        <span className="selected-icon calender_del" style={{ border: "unset", cursor: "pointer" }} onClick={() => { setShowEventPopup(false); handleRecycleBinClick("event", newEvent.id) }}>
                                            <span class="icon-Recycle_Modal"></span>
                                        </span>
                                    </>
                                )}
                                {/* 11-09-2024 end*/}
                                {/* // 12-09-2024 start */}
                                <span
                                    className="selected-icon"
                                    style={{ border: "unset", cursor: "pointer" }}
                                    onClick={() => {
                                        setShowEventPopup(false);
                                    }}
                                >
                                    <span class="icon-Close close_time_table"></span>
                                </span>
                                {/* // 12-09-2024 end */}
                            </div>
                        </div>
                        <div className="form-section-default flexc justifySp fwrap para_cus_sty ">
                            <div className="field-label full-field">
                                <label>Event</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{newEvent.title}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Category</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{categoryData.find(data => data.id === category)?.event_category_name}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Programs</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty"> <p> {newEvent.program === 0 ? "All Programs" : programsData.find(data => data.id === newEvent.program)?.program_name}</p> </div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Date</label>
                                {/* 26-8-24 */}
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{formatDate(newEvent.start)}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Time</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{foodTimeData.find(data => data.id === newEvent.startTime)?.time_name}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Date</label>
                                {/* 26-8-24 */}
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{formatDate(newEvent.end)}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Time</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{foodTimeData.find(data => data.id === newEvent.endTime)?.time_name}</div>
                            </div>
                            <div className="field-label full-field">
                                <label>Description</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{newEvent?.description}</div>
                            </div>
                            {/* <div className="flexc mt-4">
                                <button
                                    className="sp-view-btn ft-wt500 flexc cancel-btn"
                                    onClick={() => { setTitleError(null); setShowEventPopup(false); }}
                                >
                                    Cancel
                                </button>
                            </div> */}
                        </div>
                    </div>
                )}
                {/* 08-10-24 end  */}
                {/* Create New Student */}
                <div className="popup-modal-container" onSubmit={onSubmit}>
                    <div className="popup-wrap">
                        <h2 className="ft-wt700">Create New Student</h2>
                        <div className="sp-pop-header">
                            <h3 className="ft-wt600">Student Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={firstNameInputRef}
                                            type="text"
                                            value={user.first_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, first_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, genderInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                                            placeholder="Enter full name"
                                        />
                                    </div>
                                    {validationErrors.first_name && (
                                        <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus" style={{ padding: "0px" }}>
                                    <label>Gender<span className="col_red">*</span></label>
                                    <div
                                        className="field-value-holder dropbutton"
                                        ref={genderInputRef}
                                        style={{
                                            width: "100%",
                                            borderColor: validationErrors.gender ? "red" : borderColor.genderBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, programInputRef)
                                        }
                                        onFocus={() => handleFocus("gender")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    className={`${selectedGender ? 'selected-item' : ''}`}
                                                    style={{ fontSize: "13px", justifyContent: "flex-start", color: selectedGender ? '#1B252F' : '#A9AFC3' }}
                                                >
                                                    {selectedGender ? selectedGender.value : "Select Gender"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {gender1.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedGender && selectedGender.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    icon={selectedGender && selectedGender.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, gender: item.value })}
                                                >
                                                    {item.value}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {validationErrors.gender && (
                                        <p className="error pos_abo_stu">{validationErrors.gender}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus date_pic_img  date_iconpos_rel">
                                    <label>
                                        Date of Birth<span className="col_red">*</span>
                                    </label>
                                    <div
                                        className={`field-value-holder ${validationErrors.dob ? 'error' : ''}`}>
                                        <div>
                                            <DatePicker
                                                ref={dobInputRef}
                                                placeholder="DD/MM/YYYY"
                                                format={dateFormatList}
                                                onChange={ondobChange}
                                                value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                                            />
                                            <i class="icon-Timetable"></i>
                                        </div>
                                    </div>
                                    {validationErrors.dob && <p className="error pos_abo_stu">{validationErrors.dob}</p>}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Programs<span className="col_red">*</span></label>
                                    <div
                                        ref={programInputRef}
                                        className="field-value-holder dropbutton"
                                        style={{
                                            width: "100%",
                                            borderColor: validationErrors.programs_id ? "red" : borderColor.programsBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, studentInputRef)
                                        }
                                        onFocus={() => handleFocus("programs")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    className={`${selectedProgram ? 'selected-item' : ''}`}
                                                    style={{
                                                        fontSize: "13px",
                                                        color: selectedProgram ? '#1B252F' : '#A9AFC3'
                                                    }}
                                                >
                                                    {selectedProgram ? `${selectedProgram.program_name.slice(0, 20)}...` : "Select a Program"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {programs.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""}`}
                                                    icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, programs_id: parseInt(item.id) })}
                                                >
                                                    <div
                                                        data-tooltip-id={`program-tooltip-${item.id}`}
                                                        data-tooltip-content={item.program_name}
                                                        data-tooltip-place="bottom"
                                                    >
                                                        {
                                                            (item.program_name.length <= 20) ? item.program_name : `${item.program_name.slice(0, 20)}...`
                                                        }
                                                    </div>
                                                    {item.program_name.length > 20 && <Tooltip id={`program-tooltip-${item.id}`} />}
                                                    {/* {item.program_name} */}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {validationErrors.programs_id && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.programs_id}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Student Photo</label>
                                    <div className="field-value-holder">
                                        <span className="sp-upload-btn flexc justifySp" style={{
                                            color: " #A9AFC3",
                                            fontWeight: "500",
                                            fontSize: "13px"
                                        }}>
                                            {selectedImage === null ? (
                                                "Upload"
                                            ) : (
                                                <p style={{
                                                    color: " #1B252F",
                                                    fontWeight: "600",
                                                    fontSize: "13px"
                                                }} className="truncate"> {selectedImage.name}</p>
                                            )}
                                            <button className="sp-file-browse">Browse</button>
                                        </span>
                                        <input
                                            className="input-placeholder1"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            ref={imageInputRef}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, joingInputRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.profile_image && (
                    <p className="error">
                      {validationErrors.profile_image}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label half-field error_mes_cus date_pic_img pos_stu_cus  date_iconpos_rel">
                                    <label>Joining Date</label>
                                    <div className="field-value-holder">
                                        <DatePicker
                                            ref={joingInputRef}
                                            placeholder="DD/MM/YYYY"
                                            value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                                            format={dateFormatList}
                                            onChange={ondojChange}
                                        >
                                        </DatePicker>
                                        <i class="icon-Timetable"></i>
                                    </div>
                                    {/* {validationErrors.joining_date && (
                    <p className="error">
                      {validationErrors.joining_date}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Student ID</label>
                                    <div className="field-value-holder custamize_color_place">
                                        <input
                                            ref={studentInputRef}
                                            type="text"
                                            value={user.studentID}
                                            onChange={(ev) =>
                                                setUser({ ...user, studentID: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, bloodInputRef)
                                            }
                                            className={`sp-field-input`}
                                            placeholder="001"
                                        />
                                    </div>
                                </div>
                                <div className="field-label half-field error_mes_cus " style={{ padding: "0px" }}>
                                    <label>Blood Group</label>
                                    <div className="field-value-holder dropbutton" ref={bloodInputRef}
                                        style={{
                                            width: "100%",
                                            borderColor: borderColor.bloodGroupBorder,
                                        }}
                                        onKeyPress={(event) =>
                                            handleKeyPress(event, adressInputRef)
                                        }
                                        onFocus={() => handleFocus("bloodGroup")}
                                        onBlur={handleBlur}
                                        tabIndex="0"
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    style={{
                                                        fontSize: "0.8825em",
                                                        color: selectedBlood ? '#1B252F' : '#A9AFC3',
                                                        justifyContent: "flex-start"
                                                    }}
                                                >
                                                    {selectedBlood ? selectedBlood.value : "Select Blood Group"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {Blood1.map((item) => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedBlood && selectedBlood.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    icon={selectedBlood && selectedBlood.id === item.id ? HiCheck : null}
                                                    onClick={() => setUser({ ...user, blood_group: item.value })}
                                                >
                                                    {item.value}
                                                </Dropdown.Item>
                                            ))}

                                        </Dropdown>
                                    </div>
                                    {/* {validationErrors.blood_group && (
                    <p className="error">
                      {validationErrors.blood_group}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label full-field error_mes_cus">
                                    <label>Address<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="text"
                                            ref={adressInputRef}
                                            value={user.address}
                                            onChange={(ev) =>
                                                setUser({ ...user, address: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                                            placeholder="Address"
                                            defaultValue={""}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fathernameInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.address && (
                                        <p className="error">{validationErrors.address}</p>
                                    )}
                                </div>
                            </div>
                            <h3 className="ft-wt600 mt-30">Father Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={fathernameInputRef}
                                            type="text"
                                            value={user.father_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, father_name: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.father_name ? 'error' : ''}`}
                                            placeholder="Enter Full name"
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fatherphoneInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.father_name && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.father_name}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={fatherphoneInputRef}
                                            type="text"
                                            value={user.primary_phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, primary_phone: ev.target.value })
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.primary_phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, fatheremailInputRef)
                                            }
                                        />
                                    </div>
                                    {validationErrors.primary_phone && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.primary_phone}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Email</label>
                                    <div className="">
                                        <input
                                            ref={fatheremailInputRef}
                                            type="text"
                                            value={user.primary_email}
                                            onChange={(ev) =>
                                                setUser({ ...user, primary_email: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, mathernameInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input`}
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    {/* {validationErrors.primary_email && (
                    <p className="error">
                      {validationErrors.primary_email}
                    </p>
                  )} */}
                                </div>
                            </div>
                            <h3 className="ft-wt600 mt-30">Mother Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Name<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={mathernameInputRef}
                                            type="text"
                                            value={user.mother_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, mother_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, matherphoneInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.mother_name ? 'error' : ''}`}
                                            placeholder="Enter Full name"
                                        />
                                    </div>
                                    {validationErrors.mother_name && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.mother_name}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={matherphoneInputRef}
                                            type="text"
                                            value={user.secondary_phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, secondary_phone: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, matheremailInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.secondary_phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    {validationErrors.secondary_phone && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.secondary_phone}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Email</label>
                                    <div className="field-value-holder custamize_color_place">
                                        <input
                                            ref={matheremailInputRef}
                                            type="text"
                                            value={user.secondary_email}
                                            onChange={(ev) =>
                                                setUser({ ...user, secondary_email: ev.target.value })
                                            }
                                            className={`sp-field-input`}
                                            placeholder="Enter your email"
                                            onKeyDown={(event) =>
                                                handleKeyPress(event, submitButtonRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.secondary_email && (
                    <p className="error">
                      {validationErrors.secondary_email}
                    </p>
                  )} */}
                                </div>
                            </div>
                        </div>
                        <div className="sp-bottom-sticky">
                            <button
                                className="sp-btn-create"
                                ref={submitButtonRef}
                                onClick={onSubmit}
                            >
                                Create
                            </button>
                            <button className="sp-btn-cancel">Cancel</button>
                        </div>
                    </div>
                </div>
                {/* Recycle Bin Modal */}
                {
                    isRecycleBinVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4"><div className="p-4 md:p-5 text-center">
                                    <div className="round-btn rounded inline-flex alignC justify-center">
                                        <span class="icon-Recycle_Modal"></span>
                                    </div>
                                    <p className="ft-wt600 ft-16 mb-4 mt-3">{isRecycleBinStatus === "program" ? "Delete Program" : "Delete Event"}</p>
                                    <h3 className="mb-5 font-normal text-sm">
                                        {isRecycleBinStatus === "program" ? "Are you sure you want to delete this program?" : "Are you sure you want to delete this event?"}
                                    </h3>
                                    <button
                                        // onClick={() => {isRecycleBinStatus === "student" ? handleRecycleBin() : setDeleteProgramConfirmVisible(true); handleRecycleBinClose();}}
                                        onClick={() => { handleRecycleBin() }}
                                        type="button"
                                        className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                                    >
                                        Yes, Proceed
                                    </button>
                                    <button
                                        onClick={handleRecycleBinClose}
                                        type="button"
                                        style={{
                                            border: "1px solid #D7D9DD",
                                        }}
                                        className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                    >
                                        Cancel
                                    </button>

                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/* Create New Program */}
                <ProgramAddComponent
                    newProgramPopupVisible={newProgramPopupVisible}
                    setNewProgramPopupVisible={setNewProgramPopupVisible}
                    teachersData={teachersData}
                    setProgramsData={setProgramsData}
                    newProgramAddUpdateStatus={newProgramAddUpdateStatus}
                    setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
                />
                {
                    deleteProgramConfirmVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="leave_round_but  rounded inline-flex alignC justify-center">
                                        <i class="icon-Info_Modal"></i>
                                    </div>
                                    {/* <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p> */}
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to delete the program
                                    </h3>
                                    <div className="att-popup">
                                        <button
                                            onClick={handleDeleteProgramConfirmClose}
                                            type="button"
                                            className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                                            onClick={() => handleDeleteProgramConfirm()}
                                            type="button"
                                            className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                                        >
                                            Yes, Proceed
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    );
}
