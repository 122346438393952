import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../../common/components/css/style1.css";
import { baseUrl } from "../../common/common.jsx";
import validator from "validator";
import { Dropdown } from "flowbite-react";
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import { DatePicker } from "antd";
import ImportComponent from "../Import/Import.jsx";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { WindmillSpinner } from 'react-spinner-overlay';
import { Doughnut } from 'react-chartjs-2';
// import { Chart, ArcElement } from 'chart.js';
import pending from "./images/pending.svg";
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
// import Confetti from 'react-confetti-canvas';
import { LogoComponent, PreviewLogoComponent, TeacherLogoComponent } from "./Common.jsx";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";
// Chart.register(ArcElement);
import Nodata from "../nodata/Nodata.jsx";

export default function Student_list() {

  const location = useLocation();
  const navigate = useNavigate();
  const formData = new FormData();

  const gender1 = [
    {
      id: 1,
      value: "Male",
    },
    {
      id: 2,
      value: "Female",
    },
  ];

  const Blood1 = [
    {
      id: 1,
      value: "A+",
    },
    {
      id: 2,
      value: "A-",
    },
    {
      id: 3,
      value: "O+",
    },
    {
      id: 4,
      value: "B-",
    },
    {
      id: 5,
      value: "B+",
    },
    {
      id: 6,
      value: "B+",
    },
  ];

  const [user, setUser] = useState({
    school_id: localStorage.getItem("schoolId"),
    studentID: "",
    first_name: "",
    last_name: "",
    gender: "",
    programs_id: "",
    dob: "",
    joining_date: "",
    blood_group: "",
    address: "",
    father_name: "",
    mother_name: "",
    primary_email: "",
    secondary_email: "",
    primary_phone: "",
    secondary_phone: "",
    student_qr_code: "",
    status: 1,
    created_by: "",
    updated_by: "",
    profile_image: null

  });

  const [displayUser, displaySetUser] = useState({
    id: "",
    school_id: localStorage.getItem("schoolId"),
    studentID: "",
    first_name: "",
    last_name: "",
    gender: "",
    programs_id: "",
    dob: "",
    joining_date: "",
    blood_group: "",
    address: "",
    father_name: "",
    mother_name: "",
    primary_email: "",
    secondary_email: "",
    primary_phone: "",
    secondary_phone: "",
    student_qr_code: "",
    status: 1,
    created_by: "",
    updated_by: "",
    profile_image: null,
    std_color: ""
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [importstatus, setimportstatus] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUser({ ...user, profile_image: file });
    setSelectedImage(file);
  };

  const convertDBDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const dateFormatList = 'DD-MM-YYYY';

  const ondobChange = (date, dateString) => {
    setUser({ ...user, dob: dateString });
    console.log(date, dateString);
  };

  const ondojChange = (date, dateString) => {
    setUser({ ...user, joining_date: dateString });
    console.log(date, dateString);
  }

  // ------------------------------
  const handleImportStatus = (status) => {
    if (status == true) {
      fetchStudentData(programId);
      setimportstatus(false);
    }
  };

  //------------------- import students -----------
  const [selectedImport, setSelectedImport] = useState("");
  const importStudents = (event) => {
    const files = event.target.files;
    //setUser({ ...user, profile_image: files[0] });
    const importFile = event.target.files[0];
    setimportstatus(true);

    axios({
      method: "post",
      url: baseUrl + "/api/student_import",
      data: {
        school_id: localStorage.getItem("schoolId"),
        programs_id: programId,
        file: importFile,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response["data"]["status"] === true) {
        fetchStudentData(programId);
        console.log(response);
      }
    });
    //setSelectedImport(file);
  };

  const importStudents1 = () => {
    setimportstatus(true);
  };
  // ------------------------------

  const onSubmit = (ev) => {
    // ev.preventDefault();
    // Object.entries(user).forEach(([key, value]) => {
    //   formData.append(key, value);
    //   console.log(key, value);
    // });
    console.log("formData", formData)
    if (validateForm()) {
      console.log(user);
      axios({
        method: "post",
        url: baseUrl + "/api/student_add",
        data: {
          school_id: localStorage.getItem("schoolId"),
          first_name: user.first_name,
          last_name: "",
          gender: user.gender,
          dob: user.dob !== "" ? convertDBDateFormat(user.dob) : "",
          joining_date: user.joining_date !== "" ? convertDBDateFormat(user.joining_date) : "",
          // dob: convertDBDateFormat(user.dob),
          programs_id: user.programs_id,
          // joining_date: convertDBDateFormat(user.joining_date),
          blood_group: user.blood_group,
          address: user.address,
          father_name: user.father_name,
          mother_name: user.mother_name,
          primary_email: user.primary_email,
          secondary_email: user.secondary_email,
          primary_phone: user.primary_phone,
          secondary_phone: user.secondary_phone,
          profile_image: user.profile_image,
          student_qr_code: "",
          status: 1,
          created_by: localStorage.getItem('loginUserId'),
          studentID: user.studentID,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        console.log(response);
        if (response["data"]["status"] === true) {
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          displaySetUser({
            // school_id: 1,
            first_name: response["data"]["data"]["first_name"],
            //last_name: '',
            studentID: response["data"]["data"]["studentID"],
            gender: response["data"]["data"]["gender"],
            dob: response["data"]["data"]["dob"],
            joining_date: response["data"]["data"]["joining_date"],
            blood_group: response["data"]["data"]["blood_group"],
            address: response["data"]["data"]["address"],
            father_name: response["data"]["data"]["father_name"],
            mother_name: response["data"]["data"]["mother_name"],
            primary_email: response["data"]["data"]["primary_email"],
            secondary_email: response["data"]["data"]["secondary_email"],
            primary_phone: response["data"]["data"]["primary_phone"],
            secondary_phone: response["data"]["data"]["secondary_phone"],
            profile_image: response["data"]["data"]["profile_image"],
            programs_id: response["data"]["data"]["programs_id"],
            std_color: response["data"]["data"]["std_color"]
          });
          $(".overlay").removeClass("visible");
          $(".popup-modal-container").removeClass("visible");
          $(".popup-student-details").addClass("visible");
          // setTimeout(function () {
          //   $(".popup-student-details").addClass("visible");
          // }, 500);
          setUser({
            school_id: localStorage.getItem("schoolId"),
            first_name: "",
            last_name: "",
            gender: "",
            programs_id: "",
            dob: "",
            joining_date: "",
            blood_group: "",
            address: "",
            father_name: "",
            mother_name: "",
            primary_email: "",
            secondary_email: "",
            primary_phone: "",
            secondary_phone: "",
            student_qr_code: "",
            status: 1,
            created_by: "",
            updated_by: "",
            studentID: "",
            profile_image: null,
          });
          fetchStudentData(programId);
          console.log(response);
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    }
  };

  const [changeProgramVisible, setChangeProgramVisible] = useState(false);

  const handleChangeProgramClose = () => {
    setChangeProgramVisible(false);
  };

  const handleChangeProgram = () => {
    // setCheckProgramChangeStatus(true);
    setChangeProgramVisible(false);
    updateUser(true);
  };

  const [checkProgramChangeStatus, setCheckProgramChangeStatus] = useState(true);

  const updateUser = (checkProgramChangeStatus) => {
    setError(null);
    if (validateForm()) {
      if (checkProgramChangeStatus) {
        try {
          axios({
            method: "post",
            url: baseUrl + "/api/student_add",
            data: {
              id: studentId,
              school_id: localStorage.getItem("schoolId"),
              first_name: user.first_name ?? "",
              last_name: "",
              gender: user.gender ?? "",
              dob: user.dob !== "" ? convertDBDateFormat(user.dob) : "",
              joining_date: user.joining_date !== "" ? convertDBDateFormat(user.joining_date) : "",
              // dob: convertDBDateFormat(user.dob),
              programs_id: user.programs_id ?? "",
              // joining_date: convertDBDateFormat(user.joining_date),
              blood_group: user.blood_group ?? "",
              address: user.address ?? "",
              father_name: user.father_name ?? "",
              mother_name: user.mother_name ?? "",
              primary_email: user.primary_email ?? "",
              secondary_email: user.secondary_email ?? "",
              primary_phone: user.primary_phone ?? "",
              secondary_phone: user.secondary_phone ?? "",
              profile_image: user.profile_image ?? "",
              student_qr_code: "",
              status: 1,
              updated_by: localStorage.getItem('loginUserId'),
              studentID: user.studentID ?? "",
            },
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": localStorage.getItem('access_token')
            },
          }).then((response) => {
            console.log(response);
            if (response["data"]["status"] === true) {
              setSnackbarMessage(response.data.message);
              setMessageType('success');
              handleSnackbarOpen();
              if (user.programs_id == localStorage.getItem("programId")) {
                displaySetUser({
                  // school_id: 1,
                  id: response["data"]["data"]["id"],
                  first_name: response["data"]["data"]["first_name"],
                  //last_name: '',
                  studentID: response["data"]["data"]["studentID"],
                  gender: response["data"]["data"]["gender"],
                  dob: response["data"]["data"]["dob"],
                  joining_date: response["data"]["data"]["joining_date"] === "" ? null : response["data"]["data"]["joining_date"],
                  blood_group: response["data"]["data"]["blood_group"],
                  address: response["data"]["data"]["address"],
                  father_name: response["data"]["data"]["father_name"],
                  mother_name: response["data"]["data"]["mother_name"],
                  primary_email: response["data"]["data"]["primary_email"],
                  secondary_email: response["data"]["data"]["secondary_email"],
                  primary_phone: response["data"]["data"]["primary_phone"],
                  secondary_phone: response["data"]["data"]["secondary_phone"],
                  profile_image: response["data"]["data"]["profile_image"],
                  programs_id: response["data"]["data"]["programs_id"],
                  std_color: response["data"]["data"]["std_color"]
                });
                $(".overlay").removeClass("visible");
                $(".popup-modal-container").removeClass("visible");
                // setTimeout(function () {
                //   $(".popup-student-details").addClass("visible");
                // }, 2500);
                $(".popup-student-details").addClass("visible");
              } else {
                $(".overlay").removeClass("visible");
                $(".popup-modal-container").removeClass("visible");
              }
              setUser({
                school_id: localStorage.getItem("schoolId"),
                first_name: "",
                last_name: "",
                gender: "",
                programs_id: "",
                dob: "",
                joining_date: "",
                blood_group: "",
                address: "",
                father_name: "",
                mother_name: "",
                primary_email: "",
                secondary_email: "",
                primary_phone: "",
                secondary_phone: "",
                student_qr_code: "",
                status: 1,
                created_by: "",
                updated_by: "",
                studentID: "",
                profile_image: null,
              });
              fetchStudentData(programId);
              console.log(response);
            } else {
              setSnackbarMessage(response.data.message);
              setMessageType('error');
              handleSnackbarOpen();
            }
          });
        } catch (error) {
          console.log(error)
        }
      }
      else {
        setChangeProgramVisible(true);
      }
    }
  };

  const [studentData, setStudentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [programId, setProgramId] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);
  const [filterValue, setFilterValue] = useState('');

  const handleInputChange = async (event) => {
    setimportstatus(false);
    setFilterValue(event.target.value);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: event.target.value.trim(),
        programs_id: programId,
        status: activeStatus,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      console.log(data.data, "d");
      setStudentData(data.data);
      // setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clearAssignInput = async () => {
    setSearchAssignTeacherQuery('');
    setFilteredUsers(teachersData);
    // fetchAllTeachersData();
  }

  /* 31-07-2024 */
  const clearInput = async () => {
    setFilterValue('');
    const programId = localStorage.getItem("programId");
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: "",
        programs_id: programId,
        status: activeStatus,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      console.log(data.data, "d");
      setStudentData(data.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchStudentData = async (programsId) => {
  //   setimportstatus(false);
  //   setLoading(false);
  //   setProgramId(programsId);
  //   try {
  //     const postData = {
  //       school_id: localStorage.getItem("schoolId"),
  //       search: "",
  //       programs_id: programsId,
  //       status: activeStatus,
  //     };
  //     const { data } = await axios.post(baseUrl + "/api/get_student", postData, {
  //       headers: {
  //         "Authorization": localStorage.getItem('access_token')
  //       }
  //     });
  //     console.log(data.data, "d");
  //     if(data.student_count===0){
  //       setimportstatus(true);
  //     }
  //     setStudentData(data.data);
  //     setLoading(true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  
  const fetchStudentData = async (programsId) => {
    setimportstatus(false);
    // setLoading(false);
    setProgramId(programsId);
    try {
      axios({
          method: "post",
          url: baseUrl + "/api/get_student",
          data: {
            school_id: localStorage.getItem("schoolId"),
            search: "",
            programs_id: programsId,
            status: activeStatus,
          },
          headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": localStorage.getItem('access_token')
          },
      }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
              if(response.data.student_count==0){
                setimportstatus(true);
              }
              setStudentData(response.data.data);
              setLoading(true);
          }
          // else {
          //     setLoading(true);
          // }
      });
  } catch (error) {
      console.log(error);
  }
  };

  const [programsData, setProgramsData] = useState([]);
  const [programsLoading, setProgramsLoading] = useState(false);
  const [programs, setPrograms] = useState([]); //
  const [programName, setProgramName] = useState(""); //
  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        // school_id:1
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_programs",
        postData,
        {
          headers: {
            "Authorization": localStorage.getItem('access_token')
          }
        }
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
      if (localStorage.getItem("programId") === "" || localStorage.getItem("programId") === null) {
        localStorage.setItem("programId", data.data[0].id);
        localStorage.setItem("programName", data.data[0].program_name);
      }
      // setProgramName(data.data[0].program_name);
      // fetchStudentData(data.data[0].id);
      setProgramName(localStorage.getItem("programName"));
      fetchStudentData(localStorage.getItem("programId"));
    } catch (error) {
      console.log(error);
    }
  };

  const openStudentPopup = (studentData, index) => {
    setRefreshAddStudentForm(true);
    setTimeout(function () {
      setRefreshAddStudentForm(false);
    }, 50);
    fetchAttendanceDetails(studentData.id);
    setStudentPreviewIndex(index);
    displaySetUser({
      // school_id: 1,
      id: studentData.id,
      first_name: studentData.first_name,
      //last_name: '',
      studentID: studentData.studentID,
      gender: studentData.gender,
      dob: studentData.dob,
      joining_date: studentData.joining_date,
      blood_group: studentData.blood_group,
      address: studentData.address,
      father_name: studentData.father_name,
      mother_name: studentData.mother_name,
      primary_email: studentData.primary_email,
      secondary_email: studentData.secondary_email,
      primary_phone: studentData.primary_phone,
      secondary_phone: studentData.secondary_phone,
      profile_image: studentData.profile_image,
      programs_id: studentData.programs_id,
      std_color: studentData.std_color
    });
    $(".overlay").removeClass("visible");
    $(".popup-modal-container").removeClass("visible");
    $(".popup-student-details").addClass("visible");
    // setTimeout(function () {
    //   $(".popup-student-details").addClass("visible");
    // }, 500);
  };

  const [studentId, setStudentId] = useState(null);
  const openStudentUpdatePopup = (studentData) => {
    $(".popup-student-details").removeClass("visible");
    setRefreshAddStudentForm(true);
    setTimeout(function () {
      setRefreshAddStudentForm(false);
    }, 50);
    setCheckProgramChangeStatus(true);
    setError(null);
    setSelectedImage(null);
    setValidationErrors({
      first_name: "",
      gender: "",
      dob: "",
      programs_id: "",
      profile_image: "",
      joining_date: "",
      studentID: "",
      blood_group: "",
      address: "",
      father_name: "",
      primary_phone: "",
      primary_email: "",
      mother_name: "",
      secondary_phone: "",
      secondary_email: "",
    });
    setStudentId(studentData.id);
    setUser({
      school_id: localStorage.getItem("schoolId"),
      first_name: studentData.first_name,
      last_name: studentData.first_name,
      gender: studentData.gender,
      programs_id: studentData.programs_id,
      dob: studentData.dob !== null ? formatDate(studentData.dob) : "",
      joining_date: studentData.joining_date !== null ? formatDate(studentData.joining_date) : "",
      // dob: formatDate(studentData.dob),
      // joining_date: formatDate(studentData.joining_date),
      blood_group: studentData.blood_group,
      address: studentData.address,
      father_name: studentData.father_name,
      mother_name: studentData.mother_name,
      primary_email: studentData.primary_email,
      secondary_email: studentData.secondary_email,
      primary_phone: studentData.primary_phone,
      secondary_phone: studentData.secondary_phone,
      student_qr_code: "",
      status: 1,
      created_by: "",
      updated_by: "",
      studentID: studentData.studentID,
      profile_image: studentData.profile_image,
    });
    $(".overlay").addClass("visible");
    $(".popup-modal-container").addClass("visible");

  };

  const fetchPrograms = async () => {
    try {
      const response = await axios.post(baseUrl + "/api/get_programs", {
        school_id: localStorage.getItem("schoolId")
      }, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      setPrograms(response.data.data);
    } catch (error) { }
  };

  const selectedProgram = programs.find(
    (program) => program.id === user.programs_id
  );

  const selectedGender = gender1.find(
    (gender) => gender.value === user.gender
  );

  const selectedBlood = Blood1.find(
    (blood_group) => blood_group.value === user.blood_group
  );

  const firstNameInputRef = useRef(null);
  const genderInputRef = useRef(null);
  const dobInputRef = useRef(null);
  const programInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const joingInputRef = useRef(null);
  const studentInputRef = useRef(null);
  const bloodInputRef = useRef(null);
  const adressInputRef = useRef(null);
  const fathernameInputRef = useRef(null);
  const fatherphoneInputRef = useRef(null);
  const fatheremailInputRef = useRef(null);
  const mathernameInputRef = useRef(null);
  const matherphoneInputRef = useRef(null);
  const matheremailInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  const handleDropdownKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      imageInputRef.current.focus(); // Move focus to the next input field
    }
  };

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Next input ref:", nextInputRef); // Log the next input ref
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      } else {
        console.log("Next input ref is null");
      }
    }
  };

  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    gender: "",
    dob: "",
    programs_id: "",
    profile_image: "",
    joining_date: "",
    studentID: "",
    blood_group: "",
    address: "",
    father_name: "",
    primary_phone: "",
    primary_email: "",
    mother_name: "",
    secondary_phone: "",
    secondary_email: "",
  });

  const [validationDone, setValidationDone] = useState(false);
  const validateForm = () => {
    let errors = {};

    if (user.first_name === "") {
      errors.first_name = "Student name cannot be empty";
    }
    if (user.gender === "") {
      errors.gender = "Student gender cannot be empty";
    }
    if (user.dob === "") {
      errors.dob = "Student date of birth cannot be empty";
    }
    if (user.programs_id === "") {
      errors.programs_id = "Student programs cannot be empty";
    }
    if (user.address === "") {
      errors.address = "Student address cannot be empty";
    }
    if (user.father_name === "") {
      errors.father_name = "Student father name cannot be empty";
    }
    if (user.primary_phone.trim() === "") {
      errors.primary_phone = "Student father mobile number cannot be empty";
    } else if (
      !validator.isMobilePhone(user.primary_phone.trim()) ||
      user.primary_phone.trim().length !== 10
    ) {
      errors.primary_phone = "Please enter valid mobile number";
    }
    if (user.mother_name === "") {
      errors.mother_name = "Student mother name cannot be empty";
    }
    if (user.secondary_phone.trim() === "") {
      errors.secondary_phone = "Student mother mobile number cannot be empty";
    } else if (
      !validator.isMobilePhone(user.secondary_phone.trim()) ||
      user.secondary_phone.trim().length !== 10
    ) {
      errors.secondary_phone = "Please enter valid mobile number";
    }

    setValidationErrors(errors);
    setValidationDone(true);
    return Object.keys(errors).length === 0;
  };


  const handleTabSelect = (event) => {
    document.querySelectorAll(".select_field_point").forEach((element) => {
      element.style.backgroundColor = "";
    });
    event.currentTarget.style.backgroundColor = "#F4F6FF";
  };

  const handleTabUnSelect = () => {
    document.querySelectorAll(".select_field_point").forEach((element) => {
      element.style.backgroundColor = "";
    });
    // event.currentTarget.style.backgroundColor = "#F4F6FF";
  };

  // ------------------------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);

  // ----------------------12-06------------------
  const [selectedStudent, setselectedStudent] = useState([]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allStudentIds = studentData.map(student => student.id);
      setselectedStudent(allStudentIds);
    } else {
      setselectedStudent([]);
    }
  };

  const handleSelectStudent = (studentId) => {
    setselectedStudent(prevSelected =>
      prevSelected.includes(studentId)
        ? prevSelected.filter(id => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  // ----------------------13-06-----------------------
  const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);
  const [isRecycleBinStatus, setRecycleBinStatus] = useState("");
  const handleRecycleBinClick = (status) => {
    setRecycleBinStatus(status);
    if (status === "student") {
      if (selectedStudent.length !== 0) {
        setRecycleBinVisible(true);
      } else {
        setSnackbarMessage("Please Select Student");
        setMessageType('error');
        handleSnackbarOpen();
      }
    } else {
      setRecycleBinVisible(true);
    }

  };

  const handleRecycleBinClose = () => {
    setRecycleBinVisible(false);
  };

  const handleRecycleBin = async () => {
    const isStudent = isRecycleBinStatus === "student";
    const url = `${baseUrl}/api/${isStudent ? "student_delete" : "programs_delete"}`;
    const data = isStudent
      ? {
        student_ids: selectedStudent,
        programs_id: localStorage.getItem("programId"),
        updated_by: localStorage.getItem('loginUserId')
      }
      : {
        id: localStorage.getItem("programId"),
        updated_by: localStorage.getItem('loginUserId')
      };
    const headers = {
      "Content-Type": "multipart/form-data",
      "Authorization": localStorage.getItem('access_token')
    };
    console.log(data,"handleRecycleBin");
    try {
      const response = await axios.post(url, data, { headers });
      console.log(response);
      if (response.data.status === true) {
        if (!isStudent) {
          handleRecycleBinClose();
        }
        setRecycleBinVisible(false);
        setselectedStudent([]);
        setSnackbarMessage(response.data.message);
        setMessageType('success');
        handleSnackbarOpen();
        if (isStudent) {
          fetchStudentData(localStorage.getItem("programId"));
        }
        if (!isStudent) {
          localStorage.removeItem("programName");
          localStorage.removeItem("programId");     
          fetchProgramsData();
          fetchPrograms();
        }
      } else {
        setSnackbarMessage(response.data.message);
        setMessageType('error');
        handleSnackbarOpen();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [isNewProgramVisible, setNewProgramVisible] = useState(false);
  const [newProgram, setNewProgram] = useState(null);
  const [newProgramError, setNewProgramError] = useState("");
  const selectedNewProgram = programs.find(
    (program) => program.id === newProgram
  );

  // -------------------28-08-------------
  const [moveProgramConfirmVisible, setMoveProgramConfirmVisible] = useState(false);

  const handleMoveProgramConfirmClose = () => {
    setMoveProgramConfirmVisible(false);
  };

  const handleMoveProgramConfirm = () => {
    // setCheckProgramChangeStatus(true);
    setMoveProgramConfirmVisible(false);
    handleSaveNewProgram();
  };


  // -------------------23-12--------------

  const handleNewProgramPopup = () => {
    if (newProgram === null) {
      setNewProgramError("Please select Program")
    } else {
      setNewProgramError(null);
      setMoveProgramConfirmVisible(true);
      setNewProgramVisible(false);
    }
  };
  // -------------------31-08--------------

  const [deleteProgramConfirmVisible, setDeleteProgramConfirmVisible] = useState(false);

  const handleDeleteProgramConfirmClose = () => {
    setDeleteProgramConfirmVisible(false);
  };

  const handleDeleteProgramConfirm = () => {
    // setCheckProgramChangeStatus(true);
    setDeleteProgramConfirmVisible(false);
    handleRecycleBin();
  };

  // -------------------------------------------
  const handleNewProgramClick = () => {
    if (selectedStudent.length !== 0) {
      setNewProgramVisible(true);
    } else {
      setSnackbarMessage("Please Select Student");
      setMessageType('error');
      handleSnackbarOpen();
    }
  };

  const handleNewProgramClose = () => {
    setNewProgramError(null);
    setNewProgram(null);
    setNewProgramVisible(false);
  };

  const handleSaveNewProgram = async () => { 
    
    // if (newProgram === null) {
    //   setNewProgramError("Please select Program")
    // } else {
    //   setNewProgramError(null)
    // }
    if (newProgram !== null && selectedStudent.length !== 0) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/student_move_program",
          data: {
            student_ids: selectedStudent,
            programs_id: newProgram,
            school_id: localStorage.getItem("schoolId"),
            updated_by: localStorage.getItem('loginUserId')
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setFilterValue("");
            setNewProgramVisible(false);
            setselectedStudent([]);
            setNewProgram(null);
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            fetchStudentData(localStorage.getItem("programId"));
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleActiveClick = async (activeStatus) => {
    setActiveStatus(activeStatus)
    setimportstatus(false);
    setLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: "",
        programs_id: programId,
        status: activeStatus,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      console.log(data.data, "d");
      setStudentData(data.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  // -------------------------19-06-------------------------

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
  const handleOpenNewProgramPopupVisible = (status) => {
    setNewProgramAddUpdateStatus(status);
  }

  const [teachersData, setTeachersData] = useState([]);
  const fetchAllTeachersData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/teacher_list",
      data: {
        school_id: localStorage.getItem("schoolId")
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  }

  const assignStyle = {
    // position: 'absolute',
    // inset: '0px auto auto 0px',
    // margin: '0px',
    // transform: 'translate(1520px, 170px)'
    position: "absolute",
    right: "26px",
    top: "154px",
    margin: "0px",
    maxWidth: "400px",
    border: "1px solid #D9DCE6",

  }

  const [assignTeacherVisible, setAssignTeacherVisible] = useState(false);

  const toggleAssignTeacher = () => {
    setIsAddTeacherFocused(false);
    setSearchAssignTeacherQuery('');
    setFilteredUsers(teachersData);
    setAssignTeacherVisible(true);
  };

  const [searchAssignTeacherQuery, setSearchAssignTeacherQuery] = useState('');
  const [filteredAssignTeacher, setFilteredUsers] = useState([]);

  const handleAssignTeacherSearch = (event) => {
    const query = event.target.value;
    setSearchAssignTeacherQuery(query.trim());
    setFilteredUsers(teachersData.filter(user => user.first_name.toLowerCase().includes(query.toLowerCase())));
  };

  const handleAssignTeachers = async (staffId, checked) => {
    if (checked || (teachersData.filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))).length > 1)) {
      axios({
        method: "post",
        url: baseUrl + "/api/add_class_teacher",
        data: {
          staff_ids: [staffId],
          programs_id: localStorage.getItem("programId"),
          // programs_id: checked ? localStorage.getItem("programId") : "",
          updated_by: localStorage.getItem("loginUserId")
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setAssignTeacherVisible(!assignTeacherVisible);
          fetchAllTeachersData();
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          console.log(response);
        } else {
          setAssignTeacherVisible(!assignTeacherVisible);
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    } else {
      setAssignTeacherVisible(!assignTeacherVisible);
      setSnackbarMessage("At least one class teacher is required");
      setMessageType('error');
      handleSnackbarOpen();
    }
  }

  const popupRef = useRef(null);
  const editProgramPopupRef = useRef(null);
  const [editProgramVisible, setEditProgramVisible] = useState(false);
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setAssignTeacherVisible(false);
    } else if (editProgramPopupRef.current && !editProgramPopupRef.current.contains(event.target)) {
      setEditProgramVisible(false);
    }
  };

  useEffect(() => {
    
    if (assignTeacherVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [assignTeacherVisible]);

  useEffect(() => {
    if (editProgramVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editProgramVisible]);

  //

  const initialBorderColor = {
    genderBorder: "",
    programsBorder: "",
    timeBorder: "",
    bloodGroupBorder: "",
    active: "",
    moveTo: "",
    newProgram: ""
  };

  const [borderColor, setBorderColor] = useState(initialBorderColor);

  const handleFocus = (borderColor) => {
    if (borderColor === "gender") {
      setBorderColor({ ...borderColor, genderBorder: "#4D6EE3" });
    } else if (borderColor === "programs") {
      setBorderColor({ ...borderColor, programsBorder: "#4D6EE3" });
    } else if (borderColor === "bloodGroup") {
      setBorderColor({ ...borderColor, bloodGroupBorder: "#4D6EE3" });
    } else if (borderColor === "active") {
      setBorderColor({ ...borderColor, active: "#4D6EE3" });
    } else if (borderColor === "moveTo") {
      setBorderColor({ ...borderColor, moveTo: "#4D6EE3" });
    } else if (borderColor === "newProgram") {
      setBorderColor({ ...borderColor, newProgram: "#4D6EE3" });
    } else if (borderColor === "time") {
      setBorderColor({ ...borderColor, timeBorder: "#4D6EE3" });
    }
  };

  const handleBlur = () => {
    setBorderColor(initialBorderColor);
  };

  // live
  const [isFocused, setIsFocused] = useState(false);
  const [isAddTeacherFocused, setIsAddTeacherFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // State to manage calendar open/close

  const handleImageClick = () => {
    setIsOpen(!isOpen); // Toggle calendar open/close
  };

  const genderRef = useRef(null);
  const programsRef = useRef(null);
  const bloodGroupRef = useRef(null);
  const activeRef = useRef(null);
  const moveToRef = useRef(null);
  const newProgramRef = useRef(null);
  const handleClickOutside1 = (event) => {
    if (genderInputRef.current && !genderInputRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (programsRef.current && !programsRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (bloodGroupRef.current && !bloodGroupRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (activeRef.current && !activeRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (moveToRef.current && !moveToRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (newProgramRef.current && !newProgramRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside1);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
    };
  }, []);

  // ----------10-07-------------
  /* ----attendance-details------- */
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [leaveCount, setLeaveCount] = useState(0);
  const [totalDaysCount, setTotalDays] = useState(0);
  const data = {
    datasets: [
      {
        // data: [present, absents, leave, totaldays - (present + absents + leave)], // Values to display
        data: [presentCount, absentCount, leaveCount, totalDaysCount - (presentCount + absentCount + leaveCount)], // Values to display  
        // data: [155, 250 - 155], // Values to display
        backgroundColor: ['#4CAF50', '#F44336', '#FF9800', '#E0E0E0'], // Colors for the segments
        borderWidth: 0,
        hoverBackgroundColor: ['#4CAF50', '#FF9800', '#F44336', '#E0E0E0']
      }
    ]
  };

  const options = {
    rotation: -90, // Start angle in degrees
    circumference: 180, // Sweep angle in degrees
    cutout: '70%', // Inner radius as a percentage of the chart radius
    plugins: {
      tooltip: {
        enabled: true // Disable tooltips
      }
    },
    elements: {
      arc: {
        borderWidth: 0 // No border
      }
    }
  };

    // --------30-09-------------
    const [checkinStatus, setCheckinStatus] = useState(false);
    // ----------------------------

  const fetchAttendanceDetails = async (studentId) => {
    axios({
      method: "post",
      url: baseUrl + "/api/student_preview",
      data: {
        school_id: localStorage.getItem("schoolId"),
        student_id: studentId
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setPresentCount(response.data.present);
        setAbsentCount(response.data.absents);
        setLeaveCount(response.data.leave);
        setTotalDays(response.data.totaldays);
        setCheckinStatus(response.data.checkin_flg==1&&true);
        console.log(response);
      }
    });
  }

  const editProgramStyle = {
    // position: 'absolute',
    // inset: '0px auto auto 0px',
    // margin: '0px',
    // transform: 'translate(1520px, 170px)'
    position: "absolute",
    left: "430px",
    top: "55px",
    margin: "0px",
    maxWidth: "140px"
  }


  // -------16-07------------------
  // const [studentDataLength, setStudentDataLength] = useState(23);
  const [studentDataLength, setStudentDataLength] = useState(() => Math.floor(window.innerWidth / 80));

  // -----------25-07----------------

  const [notificationData, setNotificationData] = useState([]);
  const fetchNotificationDetails = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/notification_list",
      data: {
        school_id: localStorage.getItem("schoolId"),
        login_user_id: localStorage.getItem("loginUserId"),
        login_user_role: 4
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setNotificationData(response.data.data);
        console.log(response);
      }
    });
  }

  // function convertNotificationDate(notifyDate) {
  //   const date = toZonedTime(new Date(notifyDate), 'UTC');
  //   const now = new Date();
  //   const daysDifference = differenceInDays(now, date);
  //   const hoursDifference = differenceInHours(now, date);
  //   const minutesDifference = differenceInMinutes(now, date);
  //   const secondsDifference = differenceInSeconds(now, date);

  //   if (daysDifference > 0) {
  //     return format(date, 'dd MMM');
  //   }
  //   if (hoursDifference > 0) {
  //     return `${hoursDifference} hr`;
  //   }
  //   if (minutesDifference > 0) {
  //     return `${minutesDifference} minutes ago`;
  //   }
  //   if (secondsDifference > 0) {
  //     return `${secondsDifference} seconds ago`;
  //   }
  //   return 'Just now';
  // }

  // ------------27-07------------

  const handleInviteAllParents = () => {
    if (selectedStudent.length !== 0) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/student_invite",
          data: {
            student_ids: selectedStudent,
            updated_by: localStorage.getItem('loginUserId')
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            // handleInviteClick();
            setselectedStudent([])
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

  // const [showConfetti, setShowConfetti] = useState(false);
  // const { width, height } = useWindowSize();
  // const handleInviteClick = () => {
  //   setShowConfetti(true);
  //   setTimeout(() => setShowConfetti(false), 5000);
  // }

  const [refreshAddStudentForm, setRefreshAddStudentForm] = useState(false);

  // ------------------------10-09--------------------

  const [studentPreviewIndex, setStudentPreviewIndex] = useState(0);

  const [createNewProgramStatus, setCreateNewProgramStatus] = useState(false);
  useEffect(() => { 
    if(createNewProgramStatus){
      fetchPrograms();
      fetchProgramsData();
    }
   }, [createNewProgramStatus]);

   const isTextOverflow = (value, width) => {
    if (!value) return false;
    const tempElement = document.createElement('div');
    tempElement.style.cssText = `
      position: absolute;
      visibility: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-family: Arial, sans-serif;
      width: ${width}px;
    `;
    tempElement.textContent = value;
    document.body.appendChild(tempElement);
    const isOverflowing = tempElement.scrollWidth > tempElement.clientWidth;
    document.body.removeChild(tempElement);
    return isOverflowing;
  };

  useEffect(() => {
    fetchPrograms();
    fetchNotificationDetails();
    fetchProgramsData();
    fetchAllTeachersData();
    $(document).on("click", "#newStudentCreate", function () {
      setRefreshAddStudentForm(true);
      setTimeout(function () {
        setRefreshAddStudentForm(false);
      }, 50);
      setCheckProgramChangeStatus(true);
      setStudentId(null);
      setBorderColor(initialBorderColor);
      setSelectedImage(null);
      setUser({
        school_id: localStorage.getItem("schoolId"),
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        studentID: "",
        profile_image: null,
      });
      setValidationErrors({
        first_name: "",
        gender: "",
        dob: "",
        programs_id: "",
        profile_image: "",
        joining_date: "",
        studentID: "",
        blood_group: "",
        address: "",
        father_name: "",
        primary_phone: "",
        primary_email: "",
        mother_name: "",
        secondary_phone: "",
        secondary_email: "",
      });
      displaySetUser({
        id: "",
        school_id: localStorage.getItem("schoolId"),
        studentID: "",
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        profile_image: null,
        std_color: ""
      });
      $(".overlay").addClass("visible");
      $(".popup-modal-container").addClass("visible");
    });
    $(document).on("click", ".sp-btn-cancel", function () {
      setError(null);
      setCheckProgramChangeStatus(true);
      setBorderColor(initialBorderColor);
      displaySetUser({
        id: "",
        school_id: localStorage.getItem("schoolId"),
        studentID: "",
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        profile_image: null,
        std_color: ""
      });
      $(".overlay").removeClass("visible");
      $(".popup-modal-container").removeClass("visible");
      handleTabUnSelect();
    });
    // $(document).on('click', '#edit-program-icon', function () {
    //   $('.overlay').addClass('visible');
    // setTimeout(function () {
    //   $('.popup-modal-program').addClass('visible');
    // }, 500);
    // });
  }, []);

  return (
    // <CelebrationWrapper>
    <section className="staff_atten_cus">
        <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
            autoHideDuration={3000}
        />
      <div className="outer-container  input_error_bor">
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <CommonSidebarHeader />
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="name_tag_side">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    <span>
                      <i className="icon-Dashboard" />
                      <p>Dashboard</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/activity");
                    }}
                  >
                    <span>
                      <i className="icon-Activity" />
                      <p>Activity</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/calendar");
                    }}
                  >
                    <span>
                      <i className="icon-Timetable" />
                      <p>Calendar</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/staff_list");
                    }}
                  >
                    <span>
                      <i className="icon-Staff" />
                      <p>Staff</p>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible("add")}>
                  Programs{" "}
                  <span id="add-programs" className="sp-plus ">
                    +
                  </span>
                </h4>
                <div>
                  <ul>
                    {programsLoading &&
                      programsData &&
                      programsData.length > 0 ? (
                      programsData.map((data, index) => {
                        return (
                          <li
                            className={
                              data.id === parseInt(localStorage.getItem("programId")) ? "selected" : ""
                            }
                            onClick={() => {
                              $(".popup-student-details").removeClass("visible");
                              setselectedStudent([]);
                              setFilterValue("");
                              setActiveStatus(1);
                              localStorage.setItem("programId", data.id);
                              localStorage.setItem("programName", data.program_name);
                              setProgramName(data.program_name);
                              fetchStudentData(data.id);
                            }}
                          >
                            <span
                                  className="ft-400 truncate"
                                  data-tooltip-id={`program-tooltip-${data.id}`}
                                  data-tooltip-content={data.program_name}
                                  data-tooltip-place="bottom"
                                >
                                  <i className="icon-Program_1" />
                                  <p>
                                    {
                                      (data.program_name && data.program_name.length <= 25) ? data.program_name : `${data.program_name.slice(0, 25)}...`
                                    }
                                  </p>
                            </span>
                                {data.program_name.length > 25 && <Tooltip id={`program-tooltip-${data.id}`} />}
                                {/* <span>
                                  <i className="icon-Program_1" />
                                  <p>{data.program_name}</p>
                                </span> */}
                            {/* </span> */}
                          </li>
                        );
                      })
                    ) : (
                      <div className="data-loader extracss_load">
                        {/* <Loader
                        type="spinner-cub"
                        bgColor="#4D6EE3"
                        color="#6B6F8C"
                        title="Loading..."
                        size={35}
                      /> */}
                        <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                        <p>Loading...</p>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <CommonSidebarFooter />
          </div>
        </div>
        <div className="sp-right-panel">
          <div className="sp-top-bar flexc justifySp top_bar_cus exta_pad_10">
            <div className="ft-wt600 flexc pad_ic_ri stude_li_edi">
              <span className="selected-icon">
                <i className="icon-Program_1" />
              </span>
              {
                loading && localStorage.getItem("programName")&&     
              <p 
                className="program_tit"
                data-tooltip-id={`program-tooltip`}
                data-tooltip-content={localStorage.getItem("programName")}
                data-tooltip-place="bottom"
                style={{
                  cursor:localStorage.getItem("programName").length > 25&&"pointer"
                }}
              >
                {
                  (localStorage.getItem("programName").length <= 25) ? localStorage.getItem("programName") : `${localStorage.getItem("programName").slice(0, 25)}`
                }
                {/* {localStorage.getItem("programName")} */}
              </p>
              }
              {loading&& localStorage.getItem("programName")&& localStorage.getItem("programName").length > 25 && <Tooltip id={`program-tooltip`} />}
              {/* <i className={`icon-More-Icon program-icon-style ${editProgramVisible && "active-Icon"}`} onClick={() => setEditProgramVisible(true)} /> */}
              <span className="pos_fix_edit_student" ref={editProgramPopupRef}>
                {/* <i className="icon-More-Icon" /> */}
                <div className="svg_hide_drop">
                  <Dropdown
                    label={
                      <span
                        onClick={() => setEditProgramVisible(true)}
                        style={{
                          color: "#1B252F",
                          padding: "0px",
                          height: "10px"
                        }}
                      >
                        {
                          editProgramVisible === false
                            ? <i className="icon-More active_ic_more" />
                            : <i className="icon-More active_ic_more active_click_more" />
                        }
                      </span>
                    }
                    style={{ width: "100%" }}
                  >
                    <Dropdown.Item onClick={() => handleOpenNewProgramPopupVisible("edit")} className="pad_progr1">
                      <span class="icon-Student_Edit" style={{ width: '18px' }}></span>&nbsp; 
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleRecycleBinClick("program")} className="pad_progr1">
                      <span class="icon-Recycle_Modal"></span>&nbsp; 
                      Delete
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </span>
            </div>
            <div className="flexc">
              <div className="file-input-container">
                <input
                  style={{ display: "none" }}
                />
                <label
                  className="sp-view-btn ft-wt500 flexc"
                  onClick={() => {
                    importStudents1();
                  }}
                >
                  <i className="icon-Import-Outline" />
                  Import
                </label>
              </div>
              <button
                className="createN ft-wt500 mr-lf10 space_icon_pad"
                id="newStudentCreate"
              ><i class="fa-solid fa-plus"></i>New Student</button>
            </div>
          </div>
          {isLoading && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}
          <div className="sp-header bor_bot_stucus">
            <nav className="flexc">
              <div className="sp-list-view flexc selected first_list_mar_cus">
                <i className="icon-Student-List" />Student List</div>
              <div className="sp-list-view flexc"
                onClick={() => {
                  navigate("/student_attendance");
                }}><i className="icon-Attandance" />Attendance</div>
              <div className="sp-list-view flexc"
                onClick={() => { navigate("/food"); }}
              ><i className="icon-Food" />Food</div>
              <div className="sp-list-view flexc" onClick={() => { navigate("/sleep"); }}
              ><i className="icon-Sleep" />Sleep</div>
              <div className="sp-list-view flexc" onClick={() => { navigate("/student_timetable"); }}
              ><i className="icon-Calendar" />Timetable</div>
            </nav>
            <>
              {!importstatus && loading && studentData &&(
                <div className="sp-menu-bar  flexc justifySp extra_pad_men drop_list_full">
                  <div className="sp-menugroup">
                    <div className="flexc">
                      {selectedStudent.length === 0 ? (
                        <>
                          <div className="drop_move_cus glo-showdow"
                            style={{
                              width: "100%", height: "40px",
                              borderColor: borderColor.active,
                            }}
                            onKeyPress={handleDropdownKeyPress}
                            onFocus={() => handleFocus("active")}
                            onBlur={handleBlur}>
                            <Dropdown
                              label={
                                <span style={{ color: "#1B252F", fontSize: "0.8825em" }}>
                                  {activeStatus === 1 ? "Active Students" : "Inactive Students"}
                                </span>
                              }
                              style={{ width: "100%" }}
                            >
                              <Dropdown.Item
                                className={`${activeStatus === 1 ? "selected-item dd_select pad_progr" : "pad_progr"}`}
                                icon={activeStatus === 1 ? HiCheck : null}
                                onClick={() => { setFilterValue(""); handleActiveClick(1) }}
                              >
                                Active Students
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={`${activeStatus === 0 ? "selected-item dd_select pad_progr" : "pad_progr"}`}
                                icon={activeStatus === 0 ? HiCheck : null}
                                onClick={() => { setFilterValue(""); handleActiveClick(0) }}
                              >
                                Inactive Students
                              </Dropdown.Item>
                            </Dropdown>

                          </div>
                          <div className={`btn-search-bar flexc glo-showdow ${isFocused ? 'focused' : ''}`}>
                            <i className="icon-Search" />
                            {/* 31-07-2024 */}
                            <input
                              type="text"
                              placeholder="Search Students"
                              id="simple-search"
                              value={filterValue}
                              onChange={handleInputChange}
                              onFocus={() => setIsFocused(true)}
                              onBlur={() => setIsFocused(false)}
                              className="search-input"
                            />
                            {/* 31-07-2024 */}
                            <i onClick={clearInput} className={`icon-Close ${filterValue ? '' : 'hide-visibility'}`} />
                            {/* <i className="icon-Search" />
                              <input
                                type="search"
                                placeholder="Search Students"
                                value={filterValue}
                                onChange={handleInputChange}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                className="search-input"
                              /> */}
                          </div>
                          {/* <div className="btn-search-bar flexc">
                            <i className="icon-Search" />
                            <input
                              type="search"
                              placeholder="Search Students"
                              value={filterValue}
                              onChange={handleInputChange}
                            />
                          </div> */}
                        </>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <div className="drop_move_cus glo-showdow"
                            style={{
                              width: "100%",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              borderColor: borderColor.moveTo
                            }}
                            onKeyPress={handleDropdownKeyPress}
                            onFocus={() => handleFocus("moveTo")}
                            onBlur={handleBlur}
                          >
                            <Dropdown
                              label={
                                <span
                                  style={{
                                    color: "#707070", fontSize: "13px", justifyContent: "flex-start",
                                    fontWight: "500",
                                    textAlign: "left",
                                    color: "#1B252F"
                                  }}
                                >
                                  <i className="fa-solid fa-arrow-right-to-bracket"></i> Move to
                                </span>
                              }
                              style={{ width: "100%" }}
                            >
                              <Dropdown.Item onClick={handleNewProgramClick} className="pad_progr1">
                                <i className="fa-solid fa-arrows-rotate fa-flip-horizontal"></i> New Program
                              </Dropdown.Item>
                              {activeStatus === 1 && (
                                <Dropdown.Item onClick={() => handleRecycleBinClick("student")} className="pad_progr1">
                                  <i className="fa-regular fa-trash-can"></i> Recycle Bin
                                </Dropdown.Item>)
                              }
                            </Dropdown>
                          </div>
                          {activeStatus === 1 && (
                            <div className="invite_cus_div">
                              <button className="sp_mbtn flexc" onClick={() => handleInviteAllParents()}>
                                <div>
                                  <i className="icon-Invite_Parents" />
                                </div>
                                <span>Invite all Parents</span>
                              </button>
                            </div>)
                          }
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sp-menu hidden">
                    <div className="flexc"></div>
                  </div>
                  <div className="flexc sp-menugroup">
                    <div className="sp-menubar-clslist flexc" style={{ gap: "9px", alignItems: "center" }}>
                      <span className="ft-wt500 font_13">Class Teachers</span>
                      <div style={{
                        display: "flex", gap: "5px", alignItems: "baseline"
                      }}>
                        <ul className="flexc">
                          {teachersData
                            .filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId")))
                            .slice(0, 5)
                            .map(user => (
                              <li key={user.id}>
                                {
                                  user.profile_image !== null ?
                                    <img
                                      className="text-xsmall small-tag-img"
                                      src={baseUrl + "/api/" + user.profile_image}
                                    // width={29}
                                    // height={29}
                                    // style={{ borderRadius: "8px" }}
                                    /> :
                                    <TeacherLogoComponent name={user.first_name} bgColor={user.staff_color} style={"small-tag"} />
                                    // <span className="text-xsmall small-tag">{user.first_name.slice(0, 1).toUpperCase()}</span>
                                }
                              </li>
                            ))}
                          {teachersData.filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))).length > 5 && (
                            <li>
                              <div className="more-images" style={{ width: "unset" }}>
                                <span className="more-text ft-17" style={{ fontSize: "10px" }}>
                                  +{teachersData.filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))).length - 5}
                                </span>
                              </div>
                            </li>
                          )}
                        </ul>
                        <span className="sp-plus clas_plu_icon bor_rad" id="assign-teacher" style={{ background: assignTeacherVisible && "#4D6EE3" }} onClick={toggleAssignTeacher}><i class="fa-solid fa-plus" style={{ color: assignTeacherVisible && "#ffffff" }}></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="load-container">
            <div className="sp-table-load overxy-auto">
              
              <div className="sp-table-container">
                {
                /* {loading && studentData && studentData.length < 0 ? (
                  <ImportComponent
                    importStudent={"Student"}
                    handleImportStatus={handleImportStatus}
                    setProgress={setProgress} setIsLoading={setIsLoading}
                  />
                ) :  */
                importstatus ? (
                  <ImportComponent
                    importStudent={"Student"}
                    handleImportStatus={handleImportStatus}
                    setProgress={setProgress} setIsLoading={setIsLoading}
                  />
                ) : (
                  <>
                    {loading && studentData &&studentData.length !== 0 &&
                      <div className="sp-table-header extra_pad_men flexc hei_4px" style={{ paddingRight: "13px" }}>
                        <div className="table_input_sty">
                          <input
                            type="checkbox"
                            id="check-all"
                            onChange={handleSelectAll}
                            checked={selectedStudent.length === studentData.length}
                          />
                          {/* <input type="checkbox" id="check-all" /> */}
                        </div>
                        <div className="sp-title flex-2 extra_flex_cusname">
                          <span>Student name</span>
                        </div>
                        <div className="sp-title flex-2 extra_flex2_cusname">
                          <span>Father Name</span>
                        </div>
                        {/* <div className="sp-title flex-2 extra_flex2_cusname">
                          <span>Mother Name</span>
                        </div> */}
                        <div className="sp-title flex-2 extra_flex2_cusname">
                          <span>Status</span>
                        </div>
                        <div className="sp-title flex-2 extra_flex2_cusname">
                          <span>Father Number</span>
                        </div>
                        <div className="sp-title flex-2 extra_flex2_cusname">
                          <span> Mother Number</span>
                        </div>
                        <div className="sp-title flex-2">Parents Email</div>
                      </div>
                    }
                    <div className={`sp-table-body ${studentData.length == 0 && "nodata_centen_imag"}`} >
                      {loading && studentData && studentData.length > 0 ? (
                        studentData.map((student, index) => (
                          <div
                            className="select_field_point"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => handleTabSelect(event)}
                          >
                            <div className="row flexc extra_side_padcus">
                              <div className="table_input_sty">
                                <input
                                  type="checkbox"
                                  className="sub_select"
                                  onChange={() => handleSelectStudent(student.id)}
                                  checked={selectedStudent.includes(student.id)}
                                />
                                {/* <input type="checkbox" className="sub_select" /> */}
                              </div>
                              <div
                                className="sp-td-content flex-2 extra_flex_cusname truncate click-popup text-cap"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-500 flexc img_wid_cu ">
                                  {
                                    student?.profile_image !== null ?
                                      <img
                                        src={baseUrl + "/api/" + student?.profile_image}
                                        width={40}
                                        alt={student?.first_name}
                                        style={{ borderRadius: "5px" }}
                                      /> : <LogoComponent name={student?.first_name} index={index} bgColor={student?.std_color} />
                                  }

                                  {/* <span className="text-xsmall acti_post_av purpile_clr mr-rt10"
                                    style={{ backgroundColor: alternatingColors[index % 6] }}
                                  >
                                    {student?.first_name.slice(0, 1).toUpperCase()}
                                  </span> */}
                                  <span
                                    className="mr-lf10 truncate name_bold mar_ri_name"
                                    data-tooltip-id={`name-tooltip-${student?.id}`}
                                    data-tooltip-content={student?.first_name}
                                    data-tooltip-place="bottom"
                                  >
                                    {
                                      (student?.first_name && student?.first_name.length <= studentDataLength) ? student?.first_name : `${student?.first_name.slice(0, studentDataLength)}...`
                                    }
                                    {/* {student?.first_name} {student?.last_name} */}
                                  </span>
                                  {
                                    student?.first_name.length > studentDataLength &&
                                    <Tooltip id={`name-tooltip-${student?.id}`} />
                                  }

                                </span>
                              </div>
                              <div
                                // className="sp-td-content flex-2 extra_flex2_cusname truncate click-popup text-cap padr_16"
                                className={`sp-td-content flex-2 extra_flex2_cusname truncate click-popup text-cap padr_16`}
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span
                                  className="ft-400 truncate font_size_thirteen"
                                  data-tooltip-id={`father-tooltip-${student?.id}`}
                                  data-tooltip-content={student?.father_name}
                                  data-tooltip-place="bottom"
                                >
                                  {
                                    (student?.father_name && student?.father_name.length <= studentDataLength) ? student?.father_name : `${student?.father_name.slice(0, studentDataLength)}...`
                                  }
                                </span>
                                {student?.father_name.length > studentDataLength && <Tooltip id={`father-tooltip-${student?.id}`} />}
                              </div>

                              {/* <div
                                className="sp-td-content flex-2 extra_flex2_cusname truncate click-popup text-cap padr_16"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span
                                  className="ft-400 click-popup text-cap truncate "
                                  data-tooltip-id={`mother-tooltip-${student?.id}`}
                                  data-tooltip-content={student?.mother_name}
                                  data-tooltip-place="bottom"
                                >
                                  {
                                    (student?.mother_name && student?.mother_name.length <= studentDataLength) ? student?.mother_name : `${student?.mother_name.slice(0, studentDataLength)}...`
                                  }
                                </span>
                                {
                                  student?.mother_name.length > studentDataLength &&
                                  <Tooltip id={`mother-tooltip-${student?.id}`} />
                                }
                              </div> */}
                              <div
                                className="sp-td-content flex-2 extra_flex2_cusname truncate click-popup padr_16"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span
                                  className="ft-400 click-popup truncate font_size_thirteen"
                                  data-tooltip-id={`login_count-tooltip-${student?.id}`}
                                  data-tooltip-content={`${student?.login_count} User Logged in`}
                                  data-tooltip-place="bottom"
                                  style={{ color: student?.login_count > 1 ? "#24B16D" : "#F14B4B" }}
                                >
                                  {/* <div style={{ color: student?.login_count > 1 ? "#24B16D" : "#F14B4B" }}> */}
                                  {/* {student?.login_count &&(16 <= studentDataLength)  */}
                                  {`${student?.login_count} User Logged in`}
                                  {/* : `${student?.login_count} User Logged in`.slice(0, studentDataLength)}... */}
                                  {/* </div> */}
                                </span>
                                {
                                  16 > studentDataLength &&
                                  <Tooltip id={`login_count-tooltip-${student?.id}`} />
                                }
                              </div>
                              <div
                                className="sp-td-content flex-2 click-popup text-cap extra_flex2_cusname"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-400 font_size_thirteen">
                                  {student?.primary_phone}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2 click-popup text-cap extra_flex2_cusname"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-400 font_size_thirteen">
                                  {student?.secondary_phone}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-3 click-popup"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-400 text-ellp">
                                  {student?.primary_email !== "" ?
                                    <span
                                      className="sp-tag-selected font_size_thirteen"
                                      data-tooltip-id={`primary_email-tooltip-${student?.id}`}
                                      data-tooltip-content={student?.primary_email}
                                      data-tooltip-place="bottom"
                                    >
                                      {
                                        (student?.primary_email.length <= studentDataLength) ? student?.primary_email : `${student?.primary_email.slice(0, studentDataLength)}...`
                                      }
                                    </span> : "--- "
                                  }

                                  {
                                    student?.primary_email.length > studentDataLength &&
                                    <Tooltip id={`primary_email-tooltip-${student?.id}`} />
                                  }
                                  {student?.secondary_email !== "" ?
                                    <span
                                      className="sp-tag-selected mr-lf10 font_size_thirteen"
                                      data-tooltip-id={`secondary_email-tooltip-${student?.id}`}
                                      data-tooltip-content={student?.secondary_email}
                                      data-tooltip-place="bottom"
                                    >
                                      {
                                        (student?.secondary_email.length <= studentDataLength) ? student?.secondary_email : `${student?.secondary_email.slice(0, studentDataLength)}...`
                                      }
                                    </span> : "---"
                                  }

                                  {
                                    student?.secondary_email.length > studentDataLength &&
                                    <Tooltip id={`secondary_email-tooltip-${student?.id}`} />
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) :loading && studentData && studentData.length === 0 ? (
                        <Nodata contentType={filterValue === "" ? "studentList" : "studentSearch"} />
                        // <center>
                        //   <div className="sp-title flex-3 nodata_content_style">
                        //     <img src={nodata} />
                        //     <p>Data Not Found</p>
                        //   </div>
                        // </center>
                      ) : (
                        <div className="data-loader extracss_load">
                          {/* <Loader
                          type="spinner-cub"
                          bgColor="#4D6EE3"
                          color="#6B6F8C"
                          title="Loading..."
                          size={35}
                        /> */}
                          <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                          <p>Loading...</p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              
            </div>
          </div>
        </div>

        <div className="popup-modal-container" onSubmit={onSubmit}>
          <div className="popup-wrap">
            {studentId !== null ? (
              <h2 className="ft-wt700">Update Student</h2>
            ) : (
              <h2 className="ft-wt700">Create New Student</h2>
            )}
            {
              refreshAddStudentForm === false ?
                <div className="sp-pop-header">
                  <h3 className="ft-wt600">Student Details</h3>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Name<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          ref={firstNameInputRef}
                          type="text"
                          value={user.first_name}
                          onChange={(ev) =>
                            setUser({ ...user, first_name: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, genderInputRef)
                          }
                          className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                          placeholder="Enter full name"
                        />
                      </div>
                      {validationErrors.first_name && (
                        <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus" style={{ padding: "0px" }}>
                      <label>Gender<span className="col_red">*</span></label>
                      <div
                        className="field-value-holder dropbutton"
                        ref={genderInputRef}
                        style={{
                          width: "100%",
                          borderColor: validationErrors.gender ? "red" : borderColor.genderBorder,
                        }}
                        onKeyPress={(event) =>
                          handleKeyPress(event, programInputRef)
                        }
                        onFocus={() => handleFocus("gender")}
                        onBlur={handleBlur}
                        tabIndex="0"
                      >
                        <Dropdown
                          label={
                            <span
                              className={`${selectedGender ? 'selected-item' : ''}`}
                              style={{ fontSize: "13px", justifyContent: "flex-start", color: selectedGender ? '#1B252F' : '#292929' }}
                            >
                              {selectedGender ? selectedGender.value : "Select Gender"}
                            </span>
                          }
                          style={{ width: "100%" }}
                        >
                          {gender1.map((item) => (
                            <Dropdown.Item
                              key={item.id}
                              className={`${selectedGender && selectedGender.id === item.id ? "selected-item dd_select" : ""
                                }`}
                              icon={selectedGender && selectedGender.id === item.id ? HiCheck : null}
                              onClick={() => setUser({ ...user, gender: item.value })}
                            >
                              {item.value}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                      </div>
                      {validationErrors.gender && (
                        <p className="error pos_abo_stu">{validationErrors.gender}</p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus date_pic_img date_iconpos_rel">
                      <label>
                        Date of Birth<span className="col_red">*</span>
                      </label>
                      <div
                        className={`field-value-holder ${validationErrors.dob ? 'error' : ''}`}>
                        <div>
                          <DatePicker
                            ref={dobInputRef}
                            placeholder="DD/MM/YYYY"
                            format={dateFormatList}
                            onChange={ondobChange}
                            value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                          />
                        </div>
                        <i class="icon-Timetable"></i>
                      </div>
                      {validationErrors.dob && <p className="error pos_abo_stu">{validationErrors.dob}</p>}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Programs<span className="col_red">*</span></label>
                      <div
                        ref={programInputRef}
                        className="field-value-holder dropbutton"
                        style={{
                          width: "100%",
                          borderColor: validationErrors.programs_id ? "red" : borderColor.programsBorder,
                        }}
                        onKeyPress={(event) =>
                          handleKeyPress(event, studentInputRef)
                        }
                        onFocus={() => handleFocus("programs")}
                        onBlur={handleBlur}
                        tabIndex="0"
                      >
                        <Dropdown
                          label={
                            <span
                              className={`${selectedProgram ? 'selected-item' : ''}`}
                              style={{
                                fontSize: "13px",
                                color: selectedProgram ? '#1B252F' : '#292929'
                              }}
                            >
                              {selectedProgram ? `${selectedProgram.program_name.slice(0, 20)}...` : "Select a Program"}
                            </span>
                          }
                          style={{ width: "100%" }}
                        >
                          {programs.map((item) => (
                            <Dropdown.Item
                              key={item.id}
                              className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""}`}
                              icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                              onClick={() => { setCheckProgramChangeStatus(item.id == localStorage.getItem("programId")); setUser({ ...user, programs_id: parseInt(item.id) }) }}
                            >
                             <div                             
                              data-tooltip-id={`program-tooltip-${item.id}`}
                              data-tooltip-content={item.program_name}
                              data-tooltip-place="bottom"
                              >
                              {
                                (item.program_name.length <= 20) ? item.program_name : `${item.program_name.slice(0, 20)}...`
                              }
                              </div>
                              {item.program_name.length > 20 && <Tooltip id={`program-tooltip-${item.id}`} />}
                              {/* {item.program_name} */}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                      </div>
                      {validationErrors.programs_id && (
                        <p className="error pos_abo_stu">
                          {validationErrors.programs_id}
                        </p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus ">
                      <label>Student Photo</label>
                      <div className="field-value-holder">
                        <span className="sp-upload-btn flexc justifySp" style={{
                          color: " #A9AFC3",
                          fontWeight: "500",
                          fontSize: "13px"
                        }}>
                          {selectedImage === null ? (
                            (displayUser.profile_image===null||displayUser.profile_image==="")? "Upload" : 
                            <p style={{
                              color: " #1B252F",
                              fontWeight: "600",
                              fontSize: "13px"
                            }} className="truncate"> {displayUser.profile_image}</p>                           
                            // "Upload"
                          ) : (
                            <p style={{
                              color: " #1B252F",
                              fontWeight: "600",
                              fontSize: "13px"
                            }} className="truncate"> {selectedImage.name}</p>
                          )}
                          <button className="sp-file-browse">Browse</button>
                        </span>
                        <input
                          className="input-placeholder1"
                          style={{
                            cursor: "pointer"
                          }}
                          ref={imageInputRef}
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          onKeyPress={(event) =>
                            handleKeyPress(event, joingInputRef)
                          }
                        />
                      </div>
                      {/* {validationErrors.profile_image && (
                      <p className="error">
                        {validationErrors.profile_image}
                      </p>
                    )} */}
                    </div>
                    <div className="field-label half-field error_mes_cus date_pic_img">
                      <label>Joining Date</label>
                      <div className="field-value-holder date_iconpos_rel">
                        <DatePicker
                          ref={joingInputRef}
                          placeholder="DD/MM/YYYY"
                          value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                          format={dateFormatList}
                          onChange={ondojChange}
                        >
                        </DatePicker>
                        <i class="icon-Timetable"></i>
                      </div>
                      {/* {validationErrors.joining_date && (
                      <p className="error">
                        {validationErrors.joining_date}
                      </p>
                    )} */}
                    </div>
                    <div className="field-label half-field error_mes_cus ">
                      <label>Student ID</label>
                      <div className="field-value-holder custamize_color_place">
                        <input
                          ref={studentInputRef}
                          type="text"
                          value={user.studentID}
                          onChange={(ev) =>
                            setUser({ ...user, studentID: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, bloodInputRef)
                          }
                          className={`sp-field-input`}
                          placeholder="001"
                        />
                      </div>
                    </div>
                    <div className="field-label half-field error_mes_cus " style={{ padding: "0px" }}>
                      <label>Blood Group</label>
                      <div className="field-value-holder dropbutton" ref={bloodInputRef}
                        style={{
                          width: "100%",
                          borderColor: borderColor.bloodGroupBorder,
                        }}
                        onKeyPress={(event) =>
                          handleKeyPress(event, adressInputRef)
                        }
                        onFocus={() => handleFocus("bloodGroup")}
                        onBlur={handleBlur}
                        tabIndex="0"
                      >
                        <Dropdown
                          label={
                            <span
                              style={{
                                fontSize: "0.8825em",
                                color: selectedBlood ? '#1B252F' : '#292929',
                                justifyContent: "flex-start"
                              }}
                            >
                              {selectedBlood ? selectedBlood.value : "Select Blood Group"}
                            </span>
                          }
                          style={{ width: "100%" }}
                        >
                          {Blood1.map((item) => (
                            <Dropdown.Item
                              key={item.id}
                              className={`${selectedBlood && selectedBlood.id === item.id ? "selected-item dd_select" : ""
                                }`}
                              icon={selectedBlood && selectedBlood.id === item.id ? HiCheck : null}
                              onClick={() => setUser({ ...user, blood_group: item.value })}
                            >
                              {item.value}
                            </Dropdown.Item>
                          ))}

                        </Dropdown>
                      </div>
                      {/* {validationErrors.blood_group && (
                      <p className="error">
                        {validationErrors.blood_group}
                      </p>
                    )} */}
                    </div>
                    <div className="field-label full-field error_mes_cus pos_stu_cus ">
                      <label>Address<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          type="text"
                          ref={adressInputRef}
                          value={user.address}
                          onChange={(ev) =>
                            setUser({ ...user, address: ev.target.value })
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                          placeholder="Address"
                          defaultValue={""}
                          onKeyPress={(event) =>
                            handleKeyPress(event, fathernameInputRef)
                          }
                        />
                      </div>
                      {validationErrors.address && (
                        <p className="error pos_abo_stu">{validationErrors.address}</p>
                      )}
                    </div>
                  </div>
                  <h3 className="ft-wt600 mt-30">Father Details</h3>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Name<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          ref={fathernameInputRef}
                          type="text"
                          value={user.father_name}
                          onChange={(ev) =>
                            setUser({ ...user, father_name: ev.target.value })
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.father_name ? 'error' : ''}`}
                          placeholder="Enter Full name"
                          onKeyPress={(event) =>
                            handleKeyPress(event, fatherphoneInputRef)
                          }
                        />
                      </div>
                      {validationErrors.father_name && (
                        <p className="error pos_abo_stu">
                          {validationErrors.father_name}
                        </p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Phone Number<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          ref={fatherphoneInputRef}
                          type="text"
                          value={user.primary_phone}
                          onChange={(ev) =>
                            setUser({ ...user, primary_phone: ev.target.value })
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.primary_phone ? 'error' : ''}`}
                          placeholder="Phone Number"
                          onKeyPress={(event) =>
                            handleKeyPress(event, fatheremailInputRef)
                          }
                        />
                      </div>
                      {validationErrors.primary_phone && (
                        <p className="error pos_abo_stu">
                          {validationErrors.primary_phone}
                        </p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus ">
                      <label>Email</label>
                      <div className="">
                        <input
                          ref={fatheremailInputRef}
                          type="text"
                          value={user.primary_email}
                          onChange={(ev) =>
                            setUser({ ...user, primary_email: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, mathernameInputRef)
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input`}
                          placeholder="Enter your email"
                        />
                      </div>
                      {/* {validationErrors.primary_email && (
                      <p className="error">
                        {validationErrors.primary_email}
                      </p>
                    )} */}
                    </div>
                  </div>
                  <h3 className="ft-wt600 mt-30">Mother Details</h3>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Name<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          ref={mathernameInputRef}
                          type="text"
                          value={user.mother_name}
                          onChange={(ev) =>
                            setUser({ ...user, mother_name: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, matherphoneInputRef)
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.mother_name ? 'error' : ''}`}
                          placeholder="Enter Full name"
                        />
                      </div>
                      {validationErrors.mother_name && (
                        <p className="error pos_abo_stu">
                          {validationErrors.mother_name}
                        </p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Phone Number<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          ref={matherphoneInputRef}
                          type="text"
                          value={user.secondary_phone}
                          onChange={(ev) =>
                            setUser({ ...user, secondary_phone: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, matheremailInputRef)
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.secondary_phone ? 'error' : ''}`}
                          placeholder="Phone Number"
                        />
                      </div>
                      {validationErrors.secondary_phone && (
                        <p className="error pos_abo_stu">
                          {validationErrors.secondary_phone}
                        </p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus ">
                      <label>Email</label>
                      <div className="field-value-holder custamize_color_place">
                        <input
                          ref={matheremailInputRef}
                          type="text"
                          value={user.secondary_email}
                          onChange={(ev) =>
                            setUser({ ...user, secondary_email: ev.target.value })
                          }
                          className={`sp-field-input`}
                          placeholder="Enter your email"
                          onKeyDown={(event) =>
                            handleKeyPress(event, submitButtonRef)
                          }
                        />
                      </div>
                      {/* {validationErrors.secondary_email && (
                      <p className="error">
                        {validationErrors.secondary_email}
                      </p>
                    )} */}
                    </div>
                  </div>
                </div>
                : <div className="sp-pop-header">

                </div>
            }
            <div className="sp-bottom-sticky">
              {studentId === null ? (
                <button
                  className="sp-btn-create"
                  ref={submitButtonRef}
                  onClick={onSubmit}
                >
                  Create
                </button>
              ) : (
                <button className="sp-btn-create" onClick={() => updateUser(checkProgramChangeStatus)}>
                  Update
                </button>
              )}
              <button className="sp-btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
        {/* view New Student */}
        {
          refreshAddStudentForm === false ?
            <div className="popup-student-details">
              {/* <span className=" icon-Dropdown_Close flexc cp" /> */}
              <span class="icon-Close close"></span>
              <div className="student-details-page">
                <div className="sp-top-details flexc">
                  <div className="sp-profile-head">

                    {
                      displayUser.profile_image !== null ?
                        <img
                          src={baseUrl + "/api/" + displayUser.profile_image}
                          alt={displayUser.first_name}
                          width={62}
                          height={62}
                          style={{ borderRadius: '30%' }}
                        /> : <PreviewLogoComponent type={"student"} name={displayUser.first_name} index={studentPreviewIndex} bgColor={displayUser.std_color} style={"preview-logo-component"} />
                    }

                    <span className="flexc margin_20p">
                      <em className="ft-wt600 ft-18 text-cap">{displayUser.first_name}</em>
                      {checkinStatus&&<label className="sp-status">check In</label>}
                      {/* <label className="sp-status">check In</label> */}
                    </span>
                  </div>
                  <div className="sp-profile-rt flexc">
                    <span
                      className="sp-accbox_edit_icon mr-lf10 flexc"
                      style={{ borderRadius: "50%" }}
                      onClick={() => {
                        openStudentUpdatePopup(displayUser);
                      }}
                    ><span class="icon-Student_Edit"></span>
                    </span>
                  </div>
                </div>
                <div className="sp-details-box">
                  <div className="sp-tt ft-14 flexc ft-wt600">
                    <i className="icon-Invites-Outline" />
                    Student Details
                  </div>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field">
                      <label className="label-title">Gender</label>
                      <div className="label-filled text-cap">{displayUser.gender}</div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Student ID</label>
                      <div className="label-filled">{displayUser.studentID}</div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Admission Date</label>
                      <div className="label-filled">
                        {(displayUser?.joining_date !== null && displayUser?.joining_date !== "") ? convertDBDateFormat(String(displayUser.joining_date).split("T")[0]) : "--"}
                        {/* {convertDBDateFormat(String(displayUser.joining_date).split("T")[0])} */}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Date of Birth</label>
                      <div className="label-filled">
                        {(displayUser?.dob !== null && displayUser?.dob !== "") ? convertDBDateFormat(String(displayUser.dob).split("T")[0]) : "--"}
                        {/* {convertDBDateFormat(String(displayUser.dob).split("T")[0])} */}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Blood Group</label>
                      <div className="label-filled">{displayUser.blood_group}</div>
                    </div>
                    <div className="field-label full-field">
                      <label className="label-title">Address</label>
                      <div className="label-filled">{displayUser.address}</div>
                    </div>
                  </div>
                </div>
                <div class="sp-details-box">
                  <div class="sp-tt ft-14 flexc ft-wt600"><i class="icon-Invites-Outline"></i>Contact Detail</div>
                  <div class="form-section-default flexc justifySp fwrap">
                    <div class="field-label full-field">
                      <label class="label-title">Father</label>
                      <div class="flexc alignT mt-10 label-fill-value">
                        <span class="label-prf-img"><PreviewLogoComponent type={"parent"} name={displayUser.father_name} index={studentPreviewIndex + 1} style={"preview-parentlogo-component"} /></span>
                        <span class="mr-lf10">
                          <em class="ft-500 ft-14 update_list_det text-cap"> {displayUser.father_name}</em>
                          <em class="ft-400 ft-13 dis-block mt-10 update_list_det1">{displayUser.primary_phone}</em>
                        </span>
                      </div>
                    </div>
                    <div class="field-label full-field">
                      <label class="label-title">Mother</label>
                      <div class="flexc alignT mt-10 label-fill-value">
                        <span class="label-prf-img"><PreviewLogoComponent type={"parent"} name={displayUser.mother_name} index={studentPreviewIndex + 2} style={"preview-parentlogo-component"} /></span>
                        <span class="mr-lf10">
                          <em class="ft-500 ft-14 update_list_det text-cap">{displayUser.mother_name}</em>
                          <em class="ft-400 ft-13 dis-block mt-10 update_list_det1">{displayUser.secondary_phone}</em>
                        </span>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="sp-details-box">
                  <div className="sp-tt ft-14 flexc ft-wt600">
                    <i className="icon-Invites-Outline" />
                    Attendance Detail
                  </div>
                  <div className="attendance-details">
                    <div style={{ width: '300px', height: '200px', position: 'relative' }}>
                      <Doughnut data={data} options={options} />
                      <div style={{
                        position: 'absolute',
                        top: '65%',
                        left: '50%',
                        transform: 'translate(-50%, -60%)',
                        textAlign: 'center'
                      }}>
                        <div className="total-present">{presentCount + absentCount + leaveCount}</div>
                        <p>Out of {totalDaysCount} Days</p>
                      </div>
                    </div>
                  </div>
                  <div className="field-label-op">
                    <div className="flexc justifySp">
                      <span className="flexc ft-13 gap-cus-img">
                        <div style={{ fontSize: "21px" }}>
                          <span class="icon-List_Present">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        Present
                      </span>
                      <span className="ft-13">{presentCount} Days</span>
                    </div>
                    <div className="flexc justifySp  margin_20p">
                      <span className="flexc ft-13 gap-cus-img">
                        <div >
                          <span class="icon-List_Absent" style={{ fontSize: "21px" }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                          </span>
                        </div>
                        Leave
                      </span>
                      <span className="ft-13">{absentCount} Days</span>
                    </div>
                    <div className="flexc justifySp  margin_20p">
                      <span className="flexc ft-13 gap-cus-img">
                        {/* <span className="cross-ele mr-rt10" /> */}
                        <img src={pending} alt="" style={{ width: '16px' }} />
                        Leave Approval Pending
                      </span>
                      <span className="ft-13">{leaveCount} Days</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : <div className="popup-student-details">
            </div>
        }
        {/* Recycle Bin Modal */}
        {
          isRecycleBinVisible && (
            <div
              id="recycle-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-xs max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
                  {/* <button
                    type="button"
                    onClick={handleRecycleBinClose}
                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="recycle-popup"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button> */}
                  <div className="p-4 md:p-5 text-center">
                    <div className="round-btn rounded inline-flex alignC justify-center">
                      {/* <i className="icon-delete" /> */}
                      <span class="icon-Recycle_Modal"></span>
                    </div>
                    <p className="ft-wt600 ft-16 mb-4 mt-3">{isRecycleBinStatus === "student" ?"Recycle Bin":"Delete Program"}</p>
                    <h3 className="mb-5 font-normal text-sm">
                      {isRecycleBinStatus === "student" ?"Are you sure you want to move this student to Recycle Bin?":"Are you sure you want to delete this program?"}
                      
                    </h3>
                    <button
                      // onClick={() => {isRecycleBinStatus === "student" ? handleRecycleBin() : setDeleteProgramConfirmVisible(true); handleRecycleBinClose();}}
                      onClick={() => {handleRecycleBin()}}
                      type="button"
                      className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                    >
                      {isRecycleBinStatus === "student" ?"Yes, Move":"Yes, Proceed"}                      
                    </button>
                    <button
                      onClick={handleRecycleBinClose}
                      type="button"
                      style={{
                        border: "1px solid #D7D9DD",
                      }}
                      className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                    >
                      Cancel
                    </button>

                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* New Program Popup */}
        {
          isNewProgramVisible && (
            <div
              id="newprogram-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
                  <div className="p-4 md:p-5">
                    <p className="ft-wt600 ft-16 mb-4">New Program</p>
                    <div className="mb-5">
                      <label className="mb-5 font-normal text-sm">Programs</label>
                      <div
                        className="field-value-holder dropbutton"
                        style={{
                          width: "100%",
                          borderColor: newProgramError ? "red" : borderColor.newProgram,
                        }}
                        onKeyPress={handleDropdownKeyPress}
                        onFocus={() => handleFocus("newProgram")}
                        onBlur={handleBlur}
                      >
                        <Dropdown
                          label={
                            <span
                              style={{ color: "#3d3d3d", fontSize: "0.8825em" }}
                            >
                              {selectedNewProgram
                                ? `${selectedNewProgram.program_name.slice(0, 40)}...`
                                : "Select a Program"}
                            </span>
                          }
                          style={{ width: "100%" }}
                        >
                          {programs.map((item) => (
                            <Dropdown.Item
                              // className={
                              //   selectedNewProgram && selectedNewProgram.id === item.id
                              //     ? "dd_select"
                              //     : null
                              // }
                              className={`${selectedNewProgram && selectedNewProgram.id === item.id ? "selected-item dd_select" : ""}`}
                              icon={
                                selectedNewProgram && selectedNewProgram.id === item.id
                                  ? HiCheck
                                  : null
                              }
                              onClick={() =>
                                setNewProgram(parseInt(item.id))
                              }
                              key={item.id}
                            >
                             <div                             
                              data-tooltip-id={`program-tooltip-${item.id}`}
                              data-tooltip-content={item.program_name}
                              data-tooltip-place="bottom"
                              >
                              {
                                (item.program_name.length <= 20) ? item.program_name : `${item.program_name.slice(0, 20)}...`
                              }
                              </div>
                              {item.program_name.length > 20 && <Tooltip id={`program-tooltip-${item.id}`} />}
                              {/* {item.program_name} */}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                      </div>
                      {newProgramError && (
                        <span className="error">
                          {newProgramError}
                        </span>
                      )}
                    </div>
                    <div className="text-right but_new_cu">
                      <button
                        onClick={handleNewProgramClose}
                        type="button"
                        style={{
                          border: "1px solid #D7D9DD",
                        }}
                        className="can_pro_but border-full py-2.5 px-5 ft-13 text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 cancel_div_di "
                      >
                        Cancel
                      </button>
                      <button
                        // onClick={() =>{setMoveProgramConfirmVisible(true);setNewProgramVisible(false);}}
                        onClick={() =>{handleNewProgramPopup()}}
                        type="button"
                        className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* Assign Teacher */}
        {
          assignTeacherVisible && (
            <div id="assign-teacher" ref={popupRef} className="z-10 bg-white rounded-lg shadow w-full dark:bg-gray-700 border-full media_style_pop" style={assignStyle}>
              <div className="icon_clr_ser" style={{ paddingBottom: "10px" }}>
                <h3 className="ft-14 ft-wt600 p-4">Assign Teacher</h3>
                <div className={`btn-search-bar glo-showdow flexc  ${isAddTeacherFocused ? 'focused' : ''}`}>
                  <i className="icon-Search" />
                  {/* 31-07-2024 */}
                  <input
                    type="text"
                    id="simple-search"
                    placeholder="Search user..."
                    value={searchAssignTeacherQuery}
                    onChange={handleAssignTeacherSearch}
                    onFocus={() => setIsAddTeacherFocused(true)}
                    onBlur={() => setIsAddTeacherFocused(false)}
                    className="search-input"
                  />
                  {/* 31-07-2024 */}
                  <i onClick={clearAssignInput} className={`icon-Close ${searchAssignTeacherQuery ? '' : 'hide-visibility'}`} />
                  {/* <input
                      type="search"
                      placeholder="Search user..."
                      value={searchAssignTeacherQuery}
                      onChange={handleAssignTeacherSearch}
                      onFocus={() => setIsAddTeacherFocused(true)}
                      onBlur={() => setIsAddTeacherFocused(false)}
                      className="search-input"
                    /> */}
                </div>
              </div>
              <ul className="search-items overflow-y-auto cus_div_inputas">
                {filteredAssignTeacher.length!==0?filteredAssignTeacher.map(user => {
                  const isDisabled = teachersData.filter(teacher =>
                    teacher.assign_classes !== null &&
                    teacher.assign_classes.split(",").includes(localStorage.getItem("programId"))
                  ).length === 1 &&
                    user.assign_classes !== null &&
                    user.assign_classes.split(",").includes(localStorage.getItem("programId"));

                  return (
                    <li key={user.id}
                      style={{
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      onClick={() => {
                        if (!isDisabled) {
                          handleAssignTeachers(user.id, !user.assign_classes?.split(",").includes(localStorage.getItem("programId")));
                        }
                      }}>
                      <div
                        className="table_input_sty"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          style={{ pointerEvents: 'none' }} // To prevent checkbox from interfering with div click
                          type="checkbox"
                          className="sub_select"
                          disabled={isDisabled}
                          checked={user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))}
                        />
                        {
                          user.profile_image !== null ?
                            <img
                              className="text-xsmall small-tag-img"
                              src={baseUrl + "/api/" + user.profile_image}
                            /> :
                            <TeacherLogoComponent name={user.first_name} index={studentPreviewIndex} bgColor={user.staff_color} style={"small-tag"} />
                        }
                        {/* <span className="text-xsmall small-tag">{user.first_name.slice(0, 1).toUpperCase()}</span> */}
                        <span className="ft-12 ft-wt500 size_div_pad fir_size_div text-cap">{user.first_name}</span>
                        {/* <i class="fa fa-circle" style={{ fontSize: "4px", padding: "0px 3px" }}></i>
                        <span className="ft-12 size_div_pad sec_size_div">{user.email}</span> */}
                      </div>
                    </li>
                  );
                }):<li className="nodata_text_style">Data Not Found</li>}
              </ul>
            </div>
          )
        }

        {/* Create New Program */}
        <ProgramAddComponent
          newProgramPopupVisible={newProgramPopupVisible}
          setNewProgramPopupVisible={setNewProgramPopupVisible}
          teachersData={teachersData}
          setProgramsData={setProgramsData}
          newProgramAddUpdateStatus={newProgramAddUpdateStatus}
          setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
          setCreateNewProgramStatus={setCreateNewProgramStatus}
        />
        {/* Change Program confirm Modal */}
        {
          changeProgramVisible && (
            <div
              id="recycle-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-xs max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                  <div className="info_round_btn rounded inline-flex alignC justify-center">
                    <span class="icon-Info_Modal"></span>
                  </div>
                  {/* <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p> */}
                  <h3 className="mb-5 font-normal text-sm">
                    Are you sure you want to update the student program
                  </h3>
                  <div className="att-popup">
                    <button
                      onClick={handleChangeProgramClose}
                      type="button"
                      className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                      onClick={() => handleChangeProgram()}
                      type="button"
                      className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                    >
                      Yes, Proceed
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          )
        }
        {/* move Program confirm Modal */}
        {
          moveProgramConfirmVisible && (
            <div
              id="recycle-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-xs max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                  <div className="info_round_btn rounded inline-flex alignC justify-center">
                    <span class="icon-Info_Modal"></span>
                  </div>
                  {/* <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p> */}
                  <h3 className="mb-5 font-normal text-sm">
                    Are you sure you want to update the student program
                  </h3>
                  <div className="att-popup">
                    <button
                      onClick={handleMoveProgramConfirmClose}
                      type="button"
                      className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                      onClick={() => handleMoveProgramConfirm()}
                      type="button"
                      className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                    >
                      Yes, Proceed
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          )
        }
        {
          deleteProgramConfirmVisible && (
            <div
              id="recycle-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-xs max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                  <div className="info_round_btn rounded inline-flex alignC justify-center">
                    <span class="icon-Info_Modal"></span>
                  </div>
                  {/* <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p> */}
                  <h3 className="mb-5 font-normal text-sm">
                    Are you sure you want to delete the program
                  </h3>
                  <div className="att-popup">
                    <button
                      onClick={handleDeleteProgramConfirmClose}
                      type="button"
                      className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                      onClick={() => handleDeleteProgramConfirm()}
                      type="button"
                      className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                    >
                      Yes, Proceed
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          )
        }
      </div >
    </section >
    //   {showConfetti && (
    //     <Confetti
    //       width={width}
    //       height={height}
    //       paperCount={400}
    //       numberOfPieces={500}
    //       recycle={false}
    //       // ribbonParticleDrag={10}
    //       // ribbonParticleMass={1}
    //       // ribbonParticleAngle={45}
    //       // ribbonParticleThick={8.0}
    //       // ribbonParticleDist={8.0}
    //       // ribbonParticleCount={30}
    //       ribbonCount={20}
    //     />
    //   )}
    // </CelebrationWrapper>
  );
}

// const CelebrationWrapper = styled.div`
//   position: absolute;
//   height: 100vh;
//   width:100vw;
//   display: flex;
//   // flex-direction: column;
//   // align-items: center;
//   // justify-content: center;
//   background: rgba(255, 255, 255, 0.8);
//   `;
